// ---Dependencies
import { Box, CircularProgress } from "@mui/material";
import { Key, ReactElement, useEffect, useState } from "react";
import { ISchool } from "../../../../models/School";
import { IUpdateUserFormProps } from "../../../../models/Users";
// ---Types
import { ICatalogs } from "../../config/useCatalogs";
// ---Components
import { DistrictsInput } from "./DistrictsInput/DistrictsInput";
import { SchoolsInput } from "./SchoolsInput/SchoolsInput";
// ---UI Dependencies
// ---Custom Hooks
// ---Redux
// ---Config
// ---Assets
// ---Utils
// ---Constants
// ---Services/Requests
// ---Images

interface Props extends IUpdateUserFormProps {
  catalogs: ICatalogs;
}
/**
 * ConditionalForm Component: Do something
 * @returns {ReactElement}
 */
export function ConditionalForm({ formik, catalogs }: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const localRole = formik.values.role;
  const isSchoolUser = ["TEACHER", "SAFETY_TEAM", "SAFETY_COMMANDER"].includes(
    String(localRole),
  );
  const isDistrictAdmin = "DISTRICT_ADMIN" === localRole;
  // -----------------------RENDER

  if (isSchoolUser) {
    return catalogs?.lodingSchools ? (
      <LoadingSpinner />
    ) : (
      <SchoolsInput
        key={formik.values.school as unknown as Key}
        formik={formik}
        schools={catalogs.schools}
      />
    );
  }
  if (isDistrictAdmin) {
    return catalogs?.lodingDistricts ? (
      <LoadingSpinner />
    ) : (
      <DistrictsInput formik={formik} districts={catalogs.districts} />
    );
  }
  return <div />;
}

function LoadingSpinner() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
}
