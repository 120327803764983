const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: "0.75rem"
  },
  select: {
    fontSize: "0.75rem",
    marginLeft: {
      sm: "40px",
      xs: "10px",
    },
    marginRight: {
      sm: "40px",
      xs: "10px",
    },
  },
  firstPage: {
    marginLeft: {
      sm: "40px",
      xs: "10px",
    },
    marginRight: 0,
  },
  pageButton: {
    margin: 0,
  },
  lastPage: {
    marginLeft: 0,
    marginRight: "4px",
  }
} as const;

export default styles;