import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  ButtonProps,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles({
  dialogTitle: {
    "& h2": {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
  },
  dialogBody: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  dialogButtonsWrapper: {
    padding: "15px",
  },
  loader: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

interface IConfirmationModal {
  open: boolean;
  title: string;
  text?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  confirmButtonAction: () => void;
  cancelButtonAction?: () => void;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  confirmButtonLoading?: boolean;
  showCancelButton?: boolean;
}
/**
 * Un modal reutilizable creado utilizando Dialgo de material-ui
 *@param {boolean} open Define si está abierto el modal
  @param {string} title El titulo del modal
  @param {string} text El texto de contenido de modal 
  @param {()=>void } confirmButtonAction La accion para el botón de confirmación 
  @param {string} confirmButtonLabel La etiqueta para el botón de confirmación
  @param {()=>void} cancelButtonAction La acción para el botón de cancelar
  @param {string} cancelButtonLabel La etiqueta para el botón de cancelar
  @param {ButtonProps} confirmButtonProps Configuración del botón de confirmación
  @param {ButtonProps} cancelButtonProps Configuración del botón de cancelación
  @param {boolean} showCancelButton Mostrar el botón de cancelación
  @param {boolean} confirmButtonLoading Mostrar una animación de carga del botón de confirmación
 *
 */

const ConfirmationModal: React.FC<IConfirmationModal> = ({
  open,
  title,
  text,
  confirmButtonAction,
  confirmButtonLabel,
  cancelButtonAction,
  cancelButtonLabel,
  confirmButtonProps,
  cancelButtonProps,
  showCancelButton = true,
  confirmButtonLoading,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} aria-labelledby="dialog-modal">
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText className={classes.dialogBody}>
            {text}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions className={classes.dialogButtonsWrapper}>
        {showCancelButton && (
          <Button
            {...cancelButtonProps}
            onClick={cancelButtonAction}
            variant="outlined"
          >
            {cancelButtonLabel || "Cancel"}
          </Button>
        )}
        <Button
          {...confirmButtonProps}
          onClick={confirmButtonAction}
          disabled={confirmButtonLoading}
          variant="contained"
        >
          {confirmButtonLabel || "Accept"}
          {confirmButtonLoading && (
            <CircularProgress className={classes.loader} size={24} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
