import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useStylesDataGrid } from "../styles";
import { Paper, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "../../../redux/typedHooks";

const DataGridAdditionalPeople = () => {
  const styles = useStylesDataGrid();
  const { data } = useSelector((store) => store.triggerEvent);

  const columns: any[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
  ];

  const rows = data.locations.flatMap((i: any) =>
    i.additionalStudents.flatMap((j: any) =>
      j
        ? {
            name: j,
            location: i.locationName,
            id: uuidv4(),
          }
        : [],
    ),
  );
  return (
    <Paper sx={{ color: "#4d4d4d" }}>
      <Typography variant="h6" sx={{ padding: 1.5 }}>
        Additional Students
      </Typography>
      <Box sx={{ height: 300, width: "100%" }}>
        <DataGrid
          className={styles.root}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
    </Paper>
  );
};

export default DataGridAdditionalPeople;
