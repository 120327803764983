import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, FocusEvent, ReactNode } from "react";

const useStyles = makeStyles({
  greenCounter: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    fontSize: "0.8rem",
    color: "green",
    padding: "0 1rem",
  },
  redCounter: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    fontSize: "0.8rem",
    color: "red",
    padding: "0 1rem",
  },
});

export interface ITextInputProps extends StandardTextFieldProps {
  customErrorHandler?: boolean;
  hasCharacterCounter?: boolean;
  noDecimalNumbers?: boolean;
  maxNumericalValue?: number;
}

type ChangeEventInput = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
type FocusEventInput = FocusEvent<HTMLTextAreaElement | HTMLInputElement>;

/**
 * Extensión del componente TextField de material ui para uso específico
 * @param {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> } onChange Funcion que evalúa al cambio
 * @param {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> }onBlur FUnción que evalúa al mover el cursor de la entrada 
 * @param {boolean}customErrorHandler Agregar un manejo de errores unico
 * @param {boolean} hasCharacterCounter Agregar un contador de caracteres
 * @param {boolean}noDecimalNumbers Evitar que se muestren números decimales
 * @param {React.ReactNode}label El contido de la tarjeta 
 * @param {unkown} value The value of the input element, required for a controlled component.
 */

const TextInput: React.FC<ITextInputProps> = (props) => {
  const {
    onChange: TextInputOnChange,
    onBlur: TextInputOnBlur,
    customErrorHandler,
    hasCharacterCounter,
    noDecimalNumbers,
    label,
    value,
    ...rest
  } = props;

  const classes = useStyles();

  const finalValue: string = typeof value === "string" ? value : "";

  const saveChanges = (event: ChangeEventInput): void => {
    if (TextInputOnChange) TextInputOnChange(event);
  };

  const textFieldOnChange = (event: ChangeEventInput): void => {
    if (props.type === "numeric") {
      if (noDecimalNumbers === true) {
        /^(\s*|\d+)$/.test(event.target.value)
          ? saveChanges(event)
          : event.preventDefault();
      } else {
        /^\d*\.?\d*$/.test(event.target.value)
          ? saveChanges(event)
          : event.preventDefault();
      }
    } else {
      saveChanges(event);
    }
  };

  const textFieldOnBlur = (event: FocusEventInput): void => {
    if (TextInputOnBlur) TextInputOnBlur(event);
  };

  return (
    <>
      <TextField
        value={value}
        label={label}
        onChange={(event) => textFieldOnChange(event)}
        onBlur={(event) => textFieldOnBlur(event)}
        variant="outlined"
        onKeyDown={
          //If input is type numeric ban this chars from being captured
          props.type === "numeric"
            ? (event) =>
                ["e", "E", "+", "-", "ñ"].includes(event.key) &&
                event.preventDefault()
            : () => {
                console.log();
              }
        }
        {...rest}
      />
      {hasCharacterCounter ? (
        <div
          className={
            finalValue.length > 255 ? classes.redCounter : classes.greenCounter
          }
        >
          <div>
            {" "}
            {finalValue.length > 255
              ? "Este campo debe tener máximo 255 caracteres"
              : ""}{" "}
          </div>
          <div> {finalValue.length}/255 </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default TextInput;
