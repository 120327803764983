// --- DEPENDENCIES
import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
// --- COMPONENTS
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { LayoutContainer } from "../CreateSchool/CreateSchool";
// --- REDUX
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch } from "../../redux/typedHooks";
import { fetchOneUser, updateUser } from "../../services/users";
// --- CONFIG
import { useFormikConfig } from "./config/useFormikConfig";
import { IUpdateUserForm } from "../../models/Users";
import { useCatalogs } from "./config/useCatalogs";
import { fitDataUpadateUser, fitReqUsertToForm } from "./config/parseUtil";
import UpdateForm from "./UpdateForm";
import { useLoading } from "../../hooks/useLoading";

/**UpdateUserSuperAdmin component: Container that handles the logic of the create user form  */
const UpdateUserSuperAdmin = () => {
  // --- CONST / HOOKS
  const { handleClose, handleOpen } = useLoading();
  const history = useHistory();
  const dispatch = useDispatch();
  const id = (useParams() as { id: string })?.id;
  const formik = useFormikConfig(onSubmit);
  const catalogs = useCatalogs();

  useEffect(() => {
    initialConfig(id);
  }, []);

  // --- MAIN METHODS
  async function onSubmit(values: IUpdateUserForm): Promise<void> {
    try {
      handleOpen();
      await updateUser(fitDataUpadateUser(values));
      handleSuccessModal();
    } catch (err: any) {
      const mssg = err?.response?.data?.message;
      const eventProgrestMessage = "The user is in an event in progress.";
      const isInEventProgress =
        err?.response?.data?.payload === eventProgrestMessage;
      if (isInEventProgress) {
        handleErrorModal(eventProgrestMessage);
      } else {
        handleErrorModal(mssg);
      }
    } finally {
      handleClose();
    }
  }

  function initialConfig(id: string) {
    fetchOneUser(id)
      .then(({ data: req }) => {
        const data = fitReqUsertToForm(req?.payload);
        formik.setFieldValue("_id", data?._id);
        formik.setFieldValue("firstName", data?.firstName);
        formik.setFieldValue("lastName", data?.lastName);
        formik.setFieldValue("phoneNumber", data?.phoneNumber);
        formik.setFieldValue("role", data?.role);
        formik.setFieldValue("school", data?.school);
        formik.setFieldValue("districts", data?.districts);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  // -----------------------MAIN METHODS

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Success",
      description: `User edited successfully`,
      action: () => history.goBack(),
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal(errMsg?: string) {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: errMsg || `Error creating User`,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  return (
    <>
      <PageTitle title="Edit User" />
      <MainLayout>
        <LayoutContainer>
          <UpdateForm catalogs={catalogs} formik={formik} />
          <Grid
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            container
            spacing={3}
            justifyContent="flex-end"
          >
            <Button onClick={() => history.goBack()} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              sx={{ marginLeft: "10px" }}
              variant="contained"
            >
              SAVE
            </Button>
          </Grid>
        </LayoutContainer>
      </MainLayout>
    </>
  );
};

export default UpdateUserSuperAdmin;
