import { CometChat } from "@cometchat-pro/chat";

export const COMET_CHAT_CONST = {
  appID: "222400583597958b",
  region: "us",
  authKey: "e9a91b070598ff8a09c860e52a64192923c88e28",
};


export const appSetting: CometChat.AppSettings =
  new CometChat.AppSettingsBuilder()
    .autoEstablishSocketConnection()
    .subscribePresenceForFriends()
    .setRegion(COMET_CHAT_CONST.region)
    .build();
