import { IFieldSchema } from "../Form";

export const loginFieldsSchema: IFieldSchema[] = [
  {
    name: "email",
    validations: {
      maxLength: {
        max: 64,
        errorMessage: "The email must not have more than 64 characters",
      },
      emailFormat: {
        errorMessage: "Invalid Email",
      },
      required: {
        errorMessage: "Email required",
      },
    },
    value: "",
  },
  {
    name: "password",
    validations: {
      minLength: {
        errorMessage: "The password must be at least 8 characters long",
        min: 8,
      },
      required: {
        errorMessage: "Password Required",
      },
    },
    value: "",
  },
  {
    name: "remindMeSession",
    value: false,
  },
];