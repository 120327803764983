// ---Dependencies
import { ReactElement } from "react";
// ---UI Dependencies
import { TextField } from "@mui/material";
// ---Types
import { IUpdateUserForm, IUpdateUserFormProps } from "../../../../models/Users";

interface Props extends IUpdateUserFormProps {
  label: string;
  valueName: keyof IUpdateUserForm;
}
/**
 * SimpleTextF Component: Do something
 * @returns {ReactElement}
 */
export function SimpleTextF(props: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { valueName, label, formik } = props;
  // -----------------------MAIN METHODS
  // -----------------------AUX METHODS
  // -----------------------RENDER
  return (
    <TextField
      label={label}
      variant="outlined"
      type="text"
      value={formik.values[valueName] || ""}
      error={!!formik.errors[valueName] && !!formik.touched[valueName]}
      helperText={
        !!formik.errors[valueName] &&
        !!formik.touched[valueName] &&
        formik.errors[valueName]
      }
      onChange={formik.handleChange(valueName)}
    />
  );
}
