import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { FC } from "react";
import { ISubFormHeadCount, ISubFormHeadCountProps } from "../interfaces";
import { subFormHeadCountSchema } from "../schemas";

const SubFormHeadCount: FC<ISubFormHeadCountProps> = ({ onSubmit }) => {
  const formik = useFormik<ISubFormHeadCount>({
    initialValues: {
      status: "",
      name: "",
    },
    validationSchema: subFormHeadCountSchema,
    onSubmit: (values) => {
      onSubmit(values);
      formik.resetForm();
    },
  });

  return (
    <Box sx={{ paddingTop: 4 }}>
      <TextField
        label="Student Name*"
        variant="outlined"
        value={formik.values.name}
        error={!!formik.errors.name && !!formik.touched.name}
        helperText={
          !!formik.errors.name && !!formik.touched.name && formik.errors.name
        }
        onChange={formik.handleChange("name")}
      />
      <FormControl
        sx={{ padding: 2 }}
        error={!!formik.errors.status && !!formik.touched.status}
      >
        <FormLabel>Status</FormLabel>
        <RadioGroup
          row
          value={formik.values.status}
          onChange={(e) => formik.setFieldValue("status", e.target.value)}
        >
          <FormControlLabel
            value="missing"
            control={<Radio />}
            label="Missing"
          />
          <FormControlLabel
            value="additional"
            control={<Radio />}
            label="Additional"
          />
        </RadioGroup>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button variant="contained" onClick={() => formik.handleSubmit()}>
          Add
        </Button>
      </Box>
    </Box>
  );
};

export default SubFormHeadCount;
