import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useState, KeyboardEvent, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { resetPassword } from "../../services/resetPassword";
import Form, { IFields, IFieldSchema } from "../Form";
import { PasswordFieldEye } from "../PasswordFieldEye/PasswordFieldEye";

import style from "./styles";

const resetPasswordSchema: IFieldSchema[] = [
  {
    name: "password",
    validations: {
      minLength: {
        errorMessage: "Password must be at least 8 characters long",
        min: 8,
      },
      required: {
        errorMessage: "Password Required",
      },
    },
    value: "",
    watch: ["confirmPassword"],
  },
  {
    name: "confirmPassword",
    validations: {
      match: {
        match: "password",
        errorMessage: "Passwords must be the same",
      },
    },
    value: "",
    watch: ["password"],
  },
];

interface IMessage {
  message: string;
  severity: string;
}

interface IParams {
  id: string | string[];
  token: string | string[];
  firstTime: string | string[];
}

interface IRequestPasswordFormProps {
  params: IParams;
}

const ResetPasswordRequestForm: React.FC<IRequestPasswordFormProps> = (
  props,
) => {
  const { params } = props;
  console.log({ params });

  const history = useHistory();
  const isPasswordCreation = params.firstTime === "true" ? true : false;
  const [loading, setLoading] = useState<boolean>(false);
  const [checkAuth, setCheckAuth] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>({
    message: "",
    severity: "",
  });
  const actionPastWord = isPasswordCreation ? "created" : "changed";
  const actionContinuosWord = isPasswordCreation ? "creating" : "changing";
  const actionBaseWord = isPasswordCreation ? "Create" : "Change";

  const handleResetPassword = async (fields: IFields): Promise<void> => {
    const newPassword = fields.confirmPassword;
    if (newPassword) {
      try {
        setLoading(true);

        const response = await resetPassword(
          params.token as string,
          newPassword as unknown as string,
          params.id as string,
        );

        if (response.data.success) {
          setMessage({
            message: `Password successfully ${actionPastWord}`,
            severity: "success",
          });
        } else {
          setMessage({
            message: `Theres been an error ${actionContinuosWord} your password`,
            severity: "error",
          });
        }
      } catch (error) {
        const err = error as AxiosError;
        setMessage({
          message: err.response?.data.payload,
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    } else {
      setMessage({
        message: "Please write a valid password",
        severity: "error",
      });
    }
  };

  const handleReturnToLogin = (): void => {
    history.push("/login");
  };

  const handleCheckbox = (): void => {
    setCheckAuth(!checkAuth);
  };

  useEffect(() => {
    if (isPasswordCreation === false) {
      setCheckAuth(true);
    }
  }, [checkAuth]);

  return message.severity !== "success" ? (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Form onSubmit={handleResetPassword} fields={resetPasswordSchema}>
        {({ handleSubmit, handleChange, handleBlur, valid, fields }) => {
          const isDisable = valid && checkAuth;
          console.log({ isDisable });
          console.log({ checkAuth });
          console.log({ valid });
          const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Enter" && valid) {
              handleSubmit();
            }
          };
          return (
            <Grid container direction="column">
              <Typography variant="subtitle1" sx={style.title}>
                {actionBaseWord} password
              </Typography>
              <Grid item xs={12} sx={style.input}>
                <PasswordFieldEye
                  disabled={loading}
                  sx={{ width: "100%" }}
                  label="Password"
                  type="password"
                  value={fields.password.value}
                  onBlur={handleBlur("password")}
                  onChange={handleChange("password")}
                  variant="outlined"
                  error={!!fields.password.errors.length}
                  helperText={fields.password.errors.join(". ")}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ ...style.input, marginBottom: "10px !important" }}
              >
                <PasswordFieldEye
                  disabled={loading}
                  sx={{ width: "100%" }}
                  label="Confirm Password"
                  type="password"
                  value={fields.confirmPassword.value}
                  onBlur={handleBlur("confirmPassword")}
                  onChange={handleChange("confirmPassword")}
                  variant="outlined"
                  error={!!fields.confirmPassword.errors.length}
                  helperText={fields.confirmPassword.errors.join(". ")}
                  fullWidth
                  onKeyPress={handleKeyPress}
                />
              </Grid>

              {message.severity && (
                <div
                  style={{
                    color: `${message.severity === "error" ? "red" : "green"}`,
                    textAlign: "center",
                  }}
                >
                  <small>{message.message}</small>
                </div>
              )}
              {isPasswordCreation && (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justifyContent="start"
                >
                  <FormControlLabel
                    control={
                      <Checkbox name="agreement" onChange={handleCheckbox} />
                    }
                    sx={{ maxWidth: "320px", textAlign: "justify" }}
                    label={
                      <p>
                        By submiting this form and signing up for texts, you
                        consent to receive text messages (e.g notifications,
                        alerts) from <strong>Safetech</strong>. Msg & data rates
                        may apply. Msg frequency varies. Unsubscribe at any time
                        by replying STOP or by contacting the admin team.{" "}
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            window.open("/terms", "_blank");
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ color: "#36B1FC", fontWeight: "bold" }}
                          underline="none"
                        >
                          Terms of Service
                        </Link>
                      </p>
                    }
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
              >
                {message.severity === "error" ? (
                  <Button
                    onClick={handleReturnToLogin}
                    variant="contained"
                    color="primary"
                    sx={style.confirmButton}
                  >
                    Return to login
                  </Button>
                ) : loading ? (
                  <CircularProgress data-testid="loading-indicator" />
                ) : (
                  <Button
                    onClick={handleSubmit}
                    disabled={!isDisable}
                    variant="contained"
                    color="primary"
                    sx={style.confirmButton}
                  >
                    {actionBaseWord} password
                  </Button>
                )}
              </Grid>
            </Grid>
          );
        }}
      </Form>
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography align="center" component="h1" variant="h6">
        Password successfully {actionPastWord}
      </Typography>
      <Button
        sx={{ marginTop: "2rem" }}
        onClick={handleReturnToLogin}
        variant="contained"
        color="primary"
      >
        Return to login
      </Button>
    </Grid>
  );
};

export default ResetPasswordRequestForm;
