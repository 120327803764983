import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {  PayloadOpenModal } from "../../../redux/actions/modal";
import { useDispatch } from "../../../redux/typedHooks";
import { getSchoolEventCount } from "../../../services/schools";

import { ItemGrid } from "../../DashboardSafetyCommander/components/CustomCard";

export const SchoolsMostEvents = () => {
  const [mostSchools, setMostSchools] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getSchoolEventCount()
      .then((response) => {
        const sorted = response.data.data.sort(
          (a: any, b: any) => b.eventCount - a.eventCount,
        );
        setMostSchools(sorted);
      })
      .catch((err) => {
        handleErrorModal(err);
      });
  }, []);

  function handleErrorModal(message: any) {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: message,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    // dispatch(openModal(modalConfig));
  }

  return (
    <ItemGrid>
      <Typography variant="h6" align="center" gutterBottom>
        Most Active Schools
      </Typography>
      {mostSchools.slice(0,5).map((school: any) => {
        return (
          <Box
            flexDirection="row"
            display="flex"
            alignItems="flex-end"
            
            key={school._id}
          >
            <Typography
              style={{
                fontSize: "22px",
                marginRight: "12px",
              }}
              variant="subtitle1"
              align="left"
            >
              {school.school[0] !== undefined
                ? school.school[0]?.name
                : "Dummy"}
              :
            </Typography>
            <Typography marginBottom="2px" variant="subtitle1" align="left">
              {school.eventCount}
            </Typography>
          </Box>
        );
      })}
    </ItemGrid>
  );
};
