// --- DEPENDENCIES
import { Button, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
// --- COMPONENTS
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import UserFormSuperA from "./UserFormSuperA";
import { LayoutContainer } from "../CreateSchool/CreateSchool";
// --- REDUX
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch } from "../../redux/typedHooks";
import { createUser } from "../../services/users";
// --- CONFIG
import { useFormikConfig } from "./config/useFormikConfig";
import { IUserForm } from "../../models/Users";
import { useCatalogs } from "./config/useCatalogs";
import { fitDataCreateUser } from "./config/parseUtil";
import { useLoading } from "../../hooks/useLoading";

/**CreateUserSuperadmin component: Container that handles the logic of the create user form  */
const CreateUserSuperadmin = () => {
  // --- CONST / HOOKS
  const { handleClose, handleOpen } = useLoading();
  const history = useHistory();
  const dispatch = useDispatch();
  const formik = useFormikConfig(onSubmit);
  const catalogs = useCatalogs();

  // --- MAIN METHODS
  async function onSubmit(values: IUserForm): Promise<void> {
    try {
      handleOpen();
      await createUser(fitDataCreateUser(values));
      handleSuccessModal();
    } catch (err: any) {
      const mssg = err?.response?.data?.message;
      handleErrorModal(mssg);
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Success",
      description: `User created successfully`,
      action: () => history.goBack(),
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal(errMsg?: string) {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: errMsg || `Error creating User`,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  return (
    <>
      <PageTitle title="Create User" />
      <MainLayout>
        <LayoutContainer>
          <UserFormSuperA catalogs={catalogs} formik={formik} />
          <Grid
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            container
            spacing={3}
            justifyContent="flex-end"
          >
            <Button onClick={() => history.goBack()} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              sx={{ marginLeft: "10px" }}
              variant="contained"
            >
              SAVE
            </Button>
          </Grid>
        </LayoutContainer>
      </MainLayout>
    </>
  );
};

export default CreateUserSuperadmin;
