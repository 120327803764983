import { IFieldSchema } from "../Form";
import * as yup from "yup";

export interface ILocationRegistration{
    _id?: string;
    name:string;
    school:string;
    description:string;
    indoor:boolean;
    type:string;
}

export interface ILocationUpdate{
  _id: string;
  name:string;
  school:string;
  description:string;
  indoor:boolean;
  type:string;
}

export interface ILocationForm{
  name:string;
  description:string;
  type:string;
}

export const LOCATION_SCHEMA: IFieldSchema[] = [    
    {
      name: "name",
      validations: {
        required: {
          errorMessage: "Field Required",
        },
      },
      value: "",
    },
    {
      name: "description",
      validations: {       
        required: {
          errorMessage: "Field Required",
        },
      },
      value: "",
    },
    {
      name: "indoor",
      validations: {
        boolean: {
          errorMessage: "El formato no es válido",
        },       
      },
      value: false,
    },
    {
      name: "type",
      validations: {       
        required: {
          errorMessage: "Field Required",
        },
      },
      value: "",
    },
  ];

  

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be a number value.";
const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

  /**
   * Yup Schema wich describe the data constrains of an specifc object
   */
export const LocationValidationSchema = yup.object().shape({
  name: yup.string().typeError(IS_STRING).required(REQUIRED_MESSAGE("name")),
  address: yup
    .string()
    .typeError(IS_STRING)
    .required(REQUIRED_MESSAGE("address")),
  district: yup.string().typeError(IS_STRING),
  gradeLevels: yup
    .array()
    .of(yup.string().required(REQUIRED_MESSAGE("Grade Levels")))
    .required(REQUIRED_MESSAGE("Grade Levels")),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, "The US zip code must contain 5 digits")
    .required(REQUIRED_MESSAGE("Zip Code")),
  state: yup.string().typeError(IS_STRING).required(REQUIRED_MESSAGE("State")),
  totalStudentsEnrollment: yup.number().typeError(IS_NUMBER),
});
