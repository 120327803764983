// ---Dependencies
import { useFormik } from "formik";
// ---Typpes
import { ISchoolForm } from "../../../components/SchoolForm/ISchoolFormProps";
// ---Config
import { validationSchema } from "./formValidations";

/**
 * useFormikConfig customhook: Generates the formik schema 
 * @param {(values: ISchoolForm)=> Promise<void>} onSubmit - On submit method recived from react component
 * @returns {FormikSchema}
 */
export function useFormikConfig (onSubmit: (values: ISchoolForm)=> Promise<void>){
  return useFormik<ISchoolForm>({
    initialValues: {
      name: "",
      address: "",
      district: "",
      totalStudentsEnrollment: "",
      gradeLevels: [],
      state: "",
      zipCode: "",
    },
    validationSchema,
    onSubmit,
  })
}