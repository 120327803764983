import {
  reportUpdateHeadCount,
  stopEventInProgress,
  updateHeadCount,
} from "../../services/eventInProgress";
import { IDataEvent } from "../reducers/triggerEvent";

export const CHANGE_STATUS_TRIGGER_EVENT =
  "TRIGGER_EVENT:CHANGE_STATUS_TRIGER_START";

export const changeStatusTriggerEvent = (value: boolean) => ({
  type: CHANGE_STATUS_TRIGGER_EVENT,
  payload: value,
});

interface IStopEventParam {
  school: string;
  headcount: number | undefined;
}
export interface IEditEventParam {
  headcount: number;
}

export interface IReportEditParam {
  headcount: number;
  eventId: string;
  userId: string;
}
export const STOP_EVENT = "TRIGGER_EVENT:STOP_EVENT";

export const CHANGE_HEADCOUNT = "CHANGE_HEADCOUNT";

export const REPORT_CHANGE_HEADCOUNT = "REPORT_CHANGE_HEADCOUNT";

export const SET_DATA_EVENT_IN_PROGRESS =
  "TRIGGER_EVENT:SET_DATA_EVENT_IN_PROGRESS";

export const UPDATE_HEADCOUNT_START = "UPDATE_HEADCOUNT_START";
export const UPDATE_HEADCOUNT_SUCCESS = "UPDATE_HEADCOUNT_SUCCESS";
export const UPDATE_HEADCOUNT_FAILURE = "UPDATE_HEADCOUNT_FAILURE";

export const stopEvent =
  ({ school, headcount = 1 }: IStopEventParam) =>
  async (dispatch: any) => {
    await stopEventInProgress(school, headcount);
    dispatch({
      type: STOP_EVENT,
    });
  };

export const changeHeadcount = (headcount: number) => async (dispatch: any) => {
  await updateHeadCount(headcount);
  dispatch({
    type: CHANGE_HEADCOUNT,
    payload: headcount,
  });
};

export const reportChangeHeadcount =
  (data: IReportEditParam) => async (dispatch: any) => {
    await reportUpdateHeadCount(data);
    dispatch({
      type: REPORT_CHANGE_HEADCOUNT,
      payload: data,
    });
  };
export const editHeadcount = () => (dispatch: any) => {
  dispatch({
    type: CHANGE_HEADCOUNT,
  });
};

export const finishEvent = () => (dispatch: any) => {
  dispatch({
    type: STOP_EVENT,
  });
};

export const setDataEventInProgress =
  (data: IDataEvent) => async (dispatch: any) => {
    dispatch({
      type: SET_DATA_EVENT_IN_PROGRESS,
      payload: data,
    });
  };
