import { Grid, Link } from "@mui/material";
import React from "react";

const styles = {
  terms: {
    display: "flex",
    justifyContent: "center",
    fontSize: "0.75rem",
    cursor: "pointer",
    marginTop: "24px",
  },
};

export const TermsLink: React.FC = () => {
  return (
    <Grid item xs={12} sx={styles.terms}>
      <Link
        onClick={() => {
          window.open("/terms", "_blank");
        }}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: "#36B1FC", fontWeight: "bold" }}
        underline="none"
      >
        Terms of Service
      </Link>
    </Grid>
  );
};
