import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Link,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { History } from "history";
import React, { KeyboardEvent, useEffect, useState } from "react";

import appleLogo from "../../../src/assets/images/apple-logo.png";
import googleLogo from "../../../src/assets/images/google-logo.png";
import { loginUser } from "../../redux/actions";
import { userDisabled } from "../../redux/actions/disabled";
import { changeValueError } from "../../redux/actions/LoggedUser";
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import Form, { IFields } from "../Form";
import Checkbox from "../Inputs/Checkbox";

import { loginFieldsSchema } from "./constants";
import styles, { GoogleLogo, AppleLogo } from "./styles";
import { TermsLink } from "../Terms/TermsLink";

interface ILoginForm {
  googleLogin?: boolean;
  appleLogin?: boolean;
  history?: History;
}

const LoginForm: React.VFC<ILoginForm> = ({
  history,
  googleLogin,
  appleLogin,
}) => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector((store) => store.loggedUser);
  const disabled = useSelector((store) => store.disabled);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  useEffect(() => {
    return () => {
      dispatch(changeValueError(""));
    };
  }, []);
  useEffect(() => {
    if (disabled.isDisabled) {
      const modalConfig: PayloadOpenModal = {
        title: "Confirm",
        description: disabled?.errorMsg || "",
      };
      dispatch(openModal(modalConfig));
    }
  }, [disabled?.errorMsg]);
  const handleRegister = (fields: IFields): void => {
    dispatch(loginUser(fields, fields.remindMeSession));
  };

  const handleForgotPassword = (): void => {
    history?.push("forgot-password");
  };

  const handleSignup = (): void => {
    history?.push("register");
  };

  return (
    <Form onSubmit={handleRegister} fields={loginFieldsSchema}>
      {({ handleSubmit, handleChange, handleBlur, valid, fields }) => {
        const handleKeyPress = (event: KeyboardEvent) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        };
        return (
          <Grid container direction="column">
            <Typography variant="subtitle1" sx={styles.title}>
              Log in
            </Typography>
            <Grid item xs={12} sx={styles.topInputWrapper}>
              <TextField
                disabled={loading}
                label="Email"
                value={fields.email.value}
                onChange={handleChange("email")}
                error={!!fields.email.errors.length}
                helperText={fields.email.errors.join(". ")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={styles.inputWrapper}>
              <TextField
                disabled={loading}
                label="Password"
                value={fields.password.value}
                type={showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                error={!!fields.password.errors.length || error}
                helperText={fields.password.errors.join(". ") || error}
                fullWidth
                onKeyPress={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sx={styles.optionsSection}>
              <Checkbox
                id="remindMeSession"
                name="remindMeSession"
                color="primary"
                onChange={handleChange("remindMeSession")}
                value={fields.remindMeSession.value}
                label="Keep me logged in"
              />
              <Link
                onClick={handleForgotPassword}
                underline="none"
                sx={styles.forgotPassword}
              >
                Forgot Password?
              </Link>
            </Grid>
            <Grid item xs={12} sx={styles.buttonWrapper}>
              {loading ? (
                <CircularProgress data-testid="loading-indicator" size={36} />
              ) : (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  sx={styles.loginButton}
                >
                  Login
                </Button>
              )}
            </Grid>
            <TermsLink />
            {(googleLogin || appleLogin) && (
              <Grid item xs={12}>
                {googleLogin && (
                  <Grid item xs={12} sx={styles.buttonSeparator}>
                    <Button
                      variant="outlined"
                      sx={styles.socialButton}
                      fullWidth
                      startIcon={<GoogleLogo src={googleLogo} alt="google" />}
                    >
                      Login with Google
                    </Button>
                  </Grid>
                )}
                {appleLogin && (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      sx={styles.socialButton}
                      fullWidth
                      startIcon={<AppleLogo src={appleLogo} alt="apple" />}
                    >
                      Login with Apple
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        );
      }}
    </Form>
  );
};

export default LoginForm;
