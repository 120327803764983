import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { makeStyles } from "@mui/material";
import React from "react";
import { toast, TypeOptions, ToastOptions, Slide } from "react-toastify";

import { paletteColors, theme } from "../../styles/theme";

import "./style.css";

const displayToast = (
  severity: TypeOptions,
  message: string,
  toastId?: string,
  style?: any,
) => {
  const icons = {
    success: () => <CheckCircleOutlineIcon />,
    error: () => <ErrorOutlineIcon />,
    info: () => <HelpOutlineIcon />,
    warning: () => <WarningAmberIcon />,
    default: () => <HelpOutlineIcon />,
  };

  const options: ToastOptions = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    progress: undefined,
    draggable: false,
    toastId,
    style: {
      ...style,
      // TODO: Change colors between different types
      backgroundColor:
        severity === "error"
          ? paletteColors.lightRed
          : paletteColors.successToast,
      color: severity === "error" ? "#FFF" : "#405F41",
      fontSize: "0.86rem",
      justifyContent: "flex-start",
      background: '#00000',
      
      /* top: 50 */
    },
    className: "Toastify",
    icon: icons[severity],
    transition: Slide,
    closeButton: false,
  };

  return (toast as any)[severity](message, options);
};

export { displayToast };
