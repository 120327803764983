import React, { useState, useEffect, useCallback } from "react";


import PageTitle from "../../components/DashboardLayout/PageTitle";
import LocationForm from "../../components/LocationForm/LocationFom";
import MainLayout from "../../components/MainLayout";

const LocationsRegister: React.FC = (props) => {
   
  return (
    <div>
      <PageTitle title="Add Location" enableGoBack />
      <MainLayout>
        <LocationForm action="register" locationId="1"/>
      </MainLayout>
    </div>
  );
};

export default LocationsRegister;
