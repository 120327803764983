import axios from 'axios';

import { getNewAccessToken } from '../services/refreshToken';
import getLocalAccessToken from '../utils/helpers/get-local-access-token';
import getLocalRefreshToken from '../utils/helpers/get-local-refresh-token';
import isTokenInLocalStorage from '../utils/helpers/is-token-in-local-storage';

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Added Access Token to all Request
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getLocalAccessToken();

    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      delete config.headers.Authorization;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Refresh token interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 403 &&
      error.response.data.payload === 'Invalid session [Access Expired]'
    ) {
      const refreshToken = getLocalRefreshToken();

      if (refreshToken) {
        originalRequest._retry = true;

        try {
          const {
            data: {
              payload: { accessToken },
            },
          } = await getNewAccessToken(refreshToken);

          if (isTokenInLocalStorage()) {
            localStorage.setItem('accessToken', accessToken);
          } else {
            sessionStorage.setItem('accessToken', accessToken);
          }

          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${accessToken}`;

          return axiosInstance(originalRequest);
        } catch (error) {
          console.log('Refresh token ERROR', originalRequest);
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error.response);
  },
);

export const getToken = (): string | null => {
  return sessionStorage.getItem("accessToken")
    || localStorage.getItem("accessToken");
};

export default axiosInstance;
