// ---Dependencies
import { ReactElement, useState } from "react";
// ---UI Dependencies
import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

/**
 * PasswordFieldEye Component: Extends the original TextField input from material to a Password input that shows or hide the passsword
 * @param {TextFieldProps} props - Material original props
 * @returns {ReactElement}
 */
export function PasswordFieldEye(props: TextFieldProps): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const extendedProps: TextFieldProps = {
    ...props,
    type: showPassword ? "text" : "password",
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    },
  };
  // -----------------------RENDER
  return <TextField {...extendedProps} />;
}
