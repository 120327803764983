/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardContent,
  Typography,
  Link,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { RouteChildrenProps } from "react-router-dom";

import LoginLayout from "../../components/LoginLayout";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    padding: "1rem",
    textAlign: "center",
    fontSize: "18px",
  },
  goToLogin: {
    fontSize: "0.75rem",
    cursor: "pointer",
    fontWeight: 600,
    color: "#0052CC",
  },
  bodyText: {
    fontWeight: 600,
    textAlign: "center",
    fontSize: "0.75rem",
    color: "#5D5D5D",
    marginTop: 24,
  },
  bottomWrapper: {
    borderTop: '1px solid #70707024',
    width: '100%',
    flex: 1,
    marginTop: 26,
    paddingTop: 26,
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'center'
  },
  linksWrapper: {
    marginTop: 40
  },
  bottomLink: {
    color: '#5D5D5D',
    fontSize: '0.75rem',
    textUnderlineOffset: '3px',
  }
}));

const AccountCreated: React.FC<RouteChildrenProps> = (props) => {
  const classes = useStyles();

  return (
    <LoginLayout showBanner>
      <Card>
        <CardContent>
          <Typography variant="subtitle1" className={classes.title}>Account created</Typography>
          <Typography variant="body1" className={classes.bodyText}>We sent an email to “my email@mail.com” please complete de validation.</Typography>
          <Typography variant="body1" className={classes.bodyText}>If you don’t received within 5 minutes please review your spam inbox.</Typography>
          <div className={classes.bottomWrapper}>
            <Link
              onClick={() => props.history.push("login")}
              underline="none"
              className={classes.goToLogin}
            >
              Return to Log in
            </Link>
          </div>
        </CardContent>
      </Card>
      <div className={classes.linksWrapper}>
        <Link underline="always" className={classes.bottomLink}>Terms & Conditions</Link>
        <span className={classes.bottomLink}> | </span>
        <Link underline="always" className={classes.bottomLink}>Privacy Policy</Link>
        <span className={classes.bottomLink}> | </span>
        <Link underline="always" className={classes.bottomLink}>Contact Support</Link>
      </div>
    </LoginLayout>
  );
};

export default AccountCreated;
