import { combineReducers } from 'redux';

import LoggedUser from './LoggedUser';
import { DisabledReducer } from './disabled';
import { ModalReducer } from "./modal";
import Register from './register';
import { TriggerEventReducer } from './triggerEvent';

const reducers = combineReducers({
  loggedUser: LoggedUser,
  register: Register,
  modal: ModalReducer,
  triggerEvent: TriggerEventReducer,
  disabled:DisabledReducer,
});

export type RootState = ReturnType<typeof reducers>;
export default reducers;
