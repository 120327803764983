import * as yup from "yup";

const IS_STRING = "This field must be a text value.";
const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

/**
 * Yup Schema wich describe the data constrains of an specifc object
 */
export const districtValidationSchema = yup.object().shape({
  name: yup.string().typeError(IS_STRING).required(REQUIRED_MESSAGE("Name")),
  number: yup.number(),
  state: yup.string().typeError(IS_STRING).required(REQUIRED_MESSAGE("State")),
  pointOfContact: yup.string().typeError(IS_STRING),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, "The US zip code must contain 5 digits")
    .required(REQUIRED_MESSAGE("Zip Code")),
});
