import { CometChat } from "@cometchat-pro/chat";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { appSetting, COMET_CHAT_CONST } from "./services/commet-chat";

CometChat.init(COMET_CHAT_CONST.appID, appSetting).then(
  () => {
    if (CometChat.setSource) {
      CometChat.setSource("ui-kit", "web", "reactjs");
    }
    ReactDOM.render(<App />, document.getElementById("root"));
  },
  (error) => {
    console.log("Initialization failed with error:", error);
  },
);
