// ---Dependencies
import { FormikHelpers, useFormik } from "formik";
// ---Typpes
import { IUpdateUserForm } from "../../../models/Users";
// ---Config
import { updateUserValidationSchema } from "./yupSchema";


/**
 * useFormikConfig customhook: Generates the formik schema
 * @param {(values: IUpdateUserForm)=> Promise<void>} onSubmit - On submit method recived from react component
 * @returns {FormikSchema}
 */
export function useFormikConfig(
  onSubmit: (
    values: IUpdateUserForm,
    formikHelpers: FormikHelpers<IUpdateUserForm>,
  ) => Promise<void>,
) {
  return useFormik<IUpdateUserForm>({
    initialValues: {
      _id: "",
      firstName: "",
      lastName: "",
      phoneNumber: undefined,
      role: undefined,
      school: undefined,
      districts: [],
    },
    validationSchema: updateUserValidationSchema,
    onSubmit,
  });
}
