// ---Dependencies
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// ---UI Dependencies
import { Toolbar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// ---Redux
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useSelector } from "../../redux/typedHooks";
import { useDispatch } from "react-redux";
// ---Components
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu } from "../../components/DataGrid";
import MainLayout from "../../components/MainLayout";
// ---Config
import { columns } from "./config/columns";
import { ERoles } from "../routes/role-catalog";
// ---Types
import { ISchool } from "../../models/School";
import { ILoggedUserReducer } from "../../redux/reducers/LoggedUser";
// ---Services/Requests
import {
  deleteSchoolById,
  fetchSchoolsPaginated,
} from "../../services/schools";
import { fetchDistricts } from "../../services/district";
import { IDistrict } from "../../models/District";
import { formatDistritValuesFilter } from "./config/formatDistricts";
import { parseSchools } from "../UsersDistrictAdmin/config/filterOptions";

/**
 * SchoolPage Component: Generate a dynamic table that contain the data
 * of all the schools registred, also has options for inactive specific schools
 * and to create new schools
 * @returns {React.ReactElement} React.ReactElement
 */
const SchoolPage = () => {
  // -----------------------CONSTS, HOOKS, STATES
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [search, setSearch] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [filterDistricts, setFilterDistricts] = useState<IDistrict[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isDisctrictAdmin = user?.role?.code === ERoles.RL001;

  useEffect(() => {
    loadTableData();
  }, []);
  useEffect(() => {
    fetchDistricts().then(({ data }) => {
      setFilterDistricts(data);
    });
  }, []);
  // -----------------------MAIN METHODS
  function loadTableData() {
    setIsLoading(true);
    fetchSchoolsPaginated().then(({ data }) => {
      const schools = isDisctrictAdmin? parseSchools(data, user?.districts): data;
      setSchools(schools);
      setIsLoading(false);
    });
  }
  const handleDelete = async (id: string) => {
    await deleteSchoolById(id);
    const copyData = [...schools];
    const objIndex = copyData.findIndex((school) => school._id === id);
    copyData[objIndex].disabled = !copyData[objIndex].disabled;
    setSchools(copyData);
  };
  const handleOpenModal = (isActivate: boolean, id: string) => {
    const activationType = isActivate ? "ACTIVATE" : "DEACTIVATE";
    const modalConfig: PayloadOpenModal = {
      title: "Confirm",
      description: `Would you like to ${activationType} the School?`,
      action: () => {
        handleDelete(id);
      },
    };
    dispatch(openModal(modalConfig));
  };
  const renderModalMenu = (id: string, isActivate: boolean) => {
    const detailsOptionsList = [
      {
        name: isActivate ? "ACTIVATE School" : "DEACTIVATE School",
        action: () => handleOpenModal(isActivate, id),
      },
      {
        name: "EDIT School",
        action: () => history.push(`/update-school/${id}`),
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  };
  const actionsColumn: GridColDef = {
    headerName: "ACTIONS",
    field: "",
    hideSortIcons: true,
    renderCell: (data) => {
      return renderModalMenu(data.row._id, data.row.disabled);
    },
  };
  const handleFilter = () =>
    schools
      .filter(
        (school) =>
          school.name.toLowerCase().includes(search.toLowerCase()) ||
          !search ||
          search === "",
      )
      .filter(
        (school) =>
          school?.district?._id.includes(district) ||
          district.includes("ALL DISTRICTS"),
      );
  // -----------------------RENDER
  return (
    <>
      <Toolbar />
      <PageTitle title="Schools" />
      <MainLayout>
        <DataGrid
          columns={columns(actionsColumn)}
          rows={handleFilter()}
          getRowId={(row) => row._id}
          loading={isLoading}
          autoHeight
          toolbarProps={{
            buttonText: "ADD SCHOOL",
            filterOptions: formatDistritValuesFilter(schools),
            buttonAction: () => history.push("create-school"),
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              setDistrict(event.target.value as string);
            },
          }}
        />
      </MainLayout>
    </>
  );
};

export default SchoolPage;
