import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "../../../redux/typedHooks";
import { msToTime } from "../../../utils/helpers/msToTime";
import { ItemGrid } from "./CustomCard";

const TimeElapsed = () => {
  const { data } = useSelector((store) => store.triggerEvent);
  const { startData } = useSelector((store) => store.triggerEvent.data);
  const [Time, setTime] = useState(0);

  useEffect(() => {
    const timeStarted = new Date(startData).getTime();
    const now = new Date().getTime();
    const count = now - timeStarted;

    setTime(count);
  }, [startData]);

  useEffect(() => {
    const inverval = setInterval(() => {
      setTime((prev) => prev + 1000);
    }, 1000);

    return () => {
      clearInterval(inverval);
    };
  }, []);

  const missingCount = data.locations.reduce(
    (acc: number, next: any) => (acc += next.missingCount),
    0,
  );
  return (
    <ItemGrid sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography variant="subtitle1" align="left">
          Time Elapsed
        </Typography>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
          sx={{ color: "blue" }}
        >
          {msToTime(Time)} hrs
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" align="left">
          Missing Count
        </Typography>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
          sx={{ color: "red" }}
        >
          {`${missingCount} Students`}
        </Typography>
      </Box>
    </ItemGrid>
  );
};

export default TimeElapsed;
