import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import { roles, rolesNames, schoolRolesNames } from "../../../constants";

import { IUserFormProps } from "../../../models/Users";
import { useSelector } from "../../../redux/typedHooks";
import { ICatalogs } from "../../CreateUserSuperadmin/config/useCatalogs";
import { SimplePasswordF } from "./SimplePasswordF/SimplePasswordF";
import { SimpleTextF } from "./SimpleTextF/SimpleTextF";

interface Props extends IUserFormProps {
  catalogs: ICatalogs;
}
const UserFormSafetyC: React.FC<Props> = ({ formik, catalogs }) => {
  // -----------------------CONSTS, HOOKS, STATES
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  // -----------------------MAIN METHODS
  function onChangeAutoComp(_: any, value: string | null) {
    const selectedRoleCode = Object.keys(rolesNames).filter(
      (key) => rolesNames[key] === value,
    )[0];
    const oldRoleName = Object.keys(roles).filter(
      (key) => roles[key] === selectedRoleCode,
    )[0];
    formik.setFieldValue("role", oldRoleName);
    formik.setFieldValue("districts", []);
    formik.setFieldValue("school", { _id: user?.school?._id });
  }
  return (
    <Box
      sx={{
        maxWidth: "500px",
        marginTop: 5,
        "& .MuiTextField-root": { m: 1, width: "500px" },
        flex: 1,
      }}
    >
      <SimpleTextF label="Email*" formik={formik} valueName="email" />
      <SimpleTextF label="First name*" formik={formik} valueName="firstName" />
      <SimpleTextF label="Last name*" formik={formik} valueName="lastName" />
      <SimpleTextF
        label="Phone number*"
        formik={formik}
        valueName="phoneNumber"
      />
      <Autocomplete
        value={formik.values.role}
        onChange={onChangeAutoComp}
        options={Object.keys(schoolRolesNames).map(
          (key: string) => schoolRolesNames[key],
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Role*"
            variant="outlined"
            error={!!formik.errors.role && !!formik.touched.role}
            helperText={
              !!formik.errors.role &&
              !!formik.touched.role &&
              formik.errors.role
            }
          />
        )}
      />
    </Box>
  );
};

export default UserFormSafetyC;
