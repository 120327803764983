import CheckIcon from "@mui/icons-material/Check";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { IconButton, Tooltip } from "@mui/material";

export type IconsType = "clear" | "missing" | "additional" | "unreported";

const withTooltip = (icon: any, text: string) => (
  <Tooltip title={text}>
    <IconButton>{icon}</IconButton>
  </Tooltip>
);

export const icons = {
  clear: withTooltip(<CheckIcon sx={{ color: "green" }} />, "Clear"),
  missing: withTooltip(<PersonOffIcon sx={{ color: "red" }} />, "Missing"),
  additional: withTooltip(
    <PersonAddIcon sx={{ color: "orange" }} />,
    "Additional",
  ),
  unreported: withTooltip(
    <SyncProblemIcon sx={{ color: "darkgray" }} />,
    "Unreported",
  ),
};
