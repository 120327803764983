// --- UI DEPENDENCIES
import { Toolbar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// --- DEPENDENCIES
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// --- COMPONENTS
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu } from "../../components/DataGrid";
import MainLayout from "../../components/MainLayout";
// --- SERVICE
import { fetchDistricts } from "../../services/district";
import districtColumns from "./config/columns";
// --- CONST
import { STATES_USA_FILTER } from "../../constants";
import { IDistrict } from "../../models/District";
import { useSelector } from "../../redux/typedHooks";

const Districts = () => {
  // --- HOOKS / STATES / SELECTORS
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const history = useHistory();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [districts, setDistricts] = useState<IDistrict[]>([]);

  useEffect(() => {
    initState(user.districts);
  }, [user.districts]);

  // --- MAIN METHODS

  async function initState(data: IDistrict[]): Promise<void> {
    try {
      setIsLoading(true);
      setDistricts(data);
      setIsLoading(false);
    } catch (error) {
      history.goBack();
    }
  }

  function renderModalMenu(id: string, isActivate: boolean) {
    const detailsOptionsList = [
      {
        name: "EDIT District",
        action: () => history.push(`/update-district/${id}`),
      },
    ];
    return <ModalMenu items={detailsOptionsList} />;
  }

  const actionsColumn: GridColDef = {
    headerName: "ACTIONS",
    field: "",
    hideSortIcons: true,
    renderCell: (data) => {
      return renderModalMenu(data.row._id, data.row.disabled);
    },
  };

  const handleFilter = () =>
    districts
      .filter(
        (district) =>
          district.name.toLowerCase().includes(search.toLowerCase()) || !search,
      )
      .filter(
        (district) =>
          district.state.includes(state) || state.includes("ALL STATES"),
      );

  // --- RENDER
  return (
    <>
      <Toolbar />
      <PageTitle title="Districts" />
      <MainLayout>
        <DataGrid
          columns={districtColumns(actionsColumn)}
          rows={handleFilter()}
          getRowId={(row) => row._id}
          loading={isLoading}
          autoHeight
          toolbarProps={{
            filterOptions: STATES_USA_FILTER.map((state) => ({
              name: state,
              value: state,
            })),
            buttonAction: () => history.push("create-district"),
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              setState(event.target.value as string);
            },
          }}
        />
      </MainLayout>
    </>
  );
};

export default Districts;
