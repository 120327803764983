// ---Dependencies
import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
//Components
import { modalStyles } from "./config/styles";
import { displayToast } from "../../../utils/helpers/displayToast";
import { ITriggerEventForm, triggerEventSchema } from "./triggerEventSchema";
import {
  addEventInProgress,
  getAllEvents,
} from "../../../services/eventInProgress";
import { emergencyType } from "./constants/const";
import { changeStatusTriggerEvent, setDataEventInProgress } from "../../../redux/actions/triggerEvent";
import { useDispatch } from "react-redux";
import NumberFormatInput from "../../../components/Inputs/NumberFormat/NumberFormat";
import { useSocket } from "../../../hooks/useSocket";
import { IDataEvent } from "../../../redux/reducers/triggerEvent";

interface ITriggerEventModal {
  open: boolean;
  handleClose: () => void;
  school: string;
}
/**
 * Modal used to create a new event in progress , it works basically the same as the confirmation modal
 * @param param0
 * @returns
 */
export const TriggerEventModal: React.FC<ITriggerEventModal> = ({
  open,
  handleClose,
  school,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { socket } = useSocket();
  const theme = useTheme();
  const [events, setEvents] = useState([]);
  const isMobile = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xl),
  );

  const formik2 = useFormik<ITriggerEventForm>({
    initialValues: {
      headcount: undefined,
      eventId: "",
      emergencyType: "",
    },
    validationSchema: triggerEventSchema,
    onSubmit: handleSubmit,
  });

  useEffect((): void => {
    fetchevents();
  }, []);

  const fetchevents = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await getAllEvents();
      if (response.data.success) {
        const eventsToSet = response.data.payload.map((event: any) => {
          return {
            value: event._id + "-" + event.name,
            name: event.name,
          };
        });
        setEvents(eventsToSet);
      }
      /*  */
    } catch (error) {
      displayToast("error", "An error has happened fetching the events");
    } finally {
      setLoading(false);
    }
  };

  async function handleSubmit(values: ITriggerEventForm) {
    try {
      const submitionForm = {
        school,
        startDate: new Date(),
        eventId: values.eventId,
        headcount: values.headcount,
        emergencyType: values?.emergencyType,
      };
      const { data } = await addEventInProgress(submitionForm);

      const eventId = data.payload.events._id;
      const updateEventState: IDataEvent = {
        emergencyType: data.payload.events.emergencyType,
        eventType: data.payload.events.eventType,
        expectedHeadCount: data.payload.events.headcount,
        startData: data.payload.events.startDate,
        locations: [],
      };

      socket?.emit("start-event", { school, eventId });
      dispatch(setDataEventInProgress(updateEventState));
      dispatch(changeStatusTriggerEvent(true));
      displayToast("success", "Event In Progress created successfully");
      handleClose();
    } catch {
      displayToast("error", "Error creating School");
    }
  }

  return (
    <Dialog open={open} aria-labelledby="dialog-modal" fullWidth>
      <DialogTitle sx={modalStyles.dialogTitle}>
        Select the event and head count to start
      </DialogTitle>
      <DialogContent style={{ paddingTop: 20 }}>
        <Grid container spacing={2} sx={modalStyles.modalForm}>
          <Grid item xs={6} xl={5} sx={modalStyles.inputStaticHeight}>
            <TextField
              select
              disabled={loading}
              label="Event"
              value={formik2.values.eventId}
              error={!!formik2.errors.eventId && !!formik2.touched.eventId}
              helperText={
                !!formik2.errors.eventId &&
                !!formik2.touched.eventId &&
                formik2.errors.eventId
              }
              onChange={formik2.handleChange("eventId")}
              style={{ width: "71%" }}
            >
              {events.map(({ name, value }, index) => (
                <MenuItem key={index} value={value}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} xl={5} sx={modalStyles.inputStaticHeight}>
            <NumberFormatInput
              disabled={loading}
              label="Headcount"
              value={formik2.values.headcount}
              onBlur={formik2.handleBlur("headcount")}
              onChange={formik2.handleChange("headcount")}
              error={!!formik2.errors.headcount && !!formik2.touched.headcount}
              helperText={
                !!formik2.errors.headcount &&
                !!formik2.touched.headcount &&
                formik2.errors.headcount
              }
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} xl={5} sx={modalStyles.inputStaticHeight}>
            <RadioGroup
              name="Emergency Type"
              value={formik2.values.emergencyType}
              onChange={(event) =>
                formik2.setFieldValue("emergencyType", event.target.value)
              }
            >
              {emergencyType.map((type, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={<Radio />}
                    label={type.name}
                    value={type.value}
                    name={type.name}
                  />
                );
              })}
            </RadioGroup>
            <FormHelperText style={{ color: "red" }}>
              {!!formik2.errors.emergencyType &&
                !!formik2.touched.emergencyType &&
                formik2.errors.emergencyType}
            </FormHelperText>
          </Grid>
        </Grid>
        <DialogActions
          sx={modalStyles.dialogButtonsWrapper}
          style={isMobile ? { paddingLeft: "2px", paddingRight: "0" } : {}}
        >
          <Button
            color="primary"
            onClick={handleClose}
            disabled={loading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => formik2.handleSubmit()}
          >
            Save
            {loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                size={24}
              />
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
