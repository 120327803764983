// ---Dependencies
import { FormikHelpers, useFormik } from "formik";
// ---Typpes
import { IDistrictForm } from "../../../components/DistrictForm/IDistrictFormProps";
// ---Config
import { districtValidationSchema } from "./schemaDistrict";

/**
 * useFormikConfig customhook: Generates the formik schema
 * @param {(values: IDistrictForm)=> Promise<void>} onSubmit - On submit method recived from react component
 * @returns {FormikSchema}
 */
export function useFormikConfig(
  onSubmit: (
    values: IDistrictForm,
    formikHelpers: FormikHelpers<IDistrictForm>,
  ) => Promise<void>,
) {
  return useFormik<IDistrictForm>({
    initialValues: {
      name: "",
      number: "",
      state: "",
      zipCode: "",
      pointOfContact: "",
    },
    validationSchema: districtValidationSchema,
    onSubmit,
  });
}
