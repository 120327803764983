import { Box } from "@mui/material";
import React from "react";
import { CometChatUI } from "../CometChatWorkspace/src";
import { ICometChatProps } from "./ICometChatProps";

const CommetChat: React.FC<ICometChatProps> = () => {
  return (
    <Box sx={{ width: "100%", height: "100%", backgroundColor: "white" }}>
      <CometChatUI />
    </Box>
  );
};

export default CommetChat;
