import { MoreHoriz, MoreVert } from "@mui/icons-material";
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Button,
  ButtonProps,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { paletteColors, theme } from "../../../styles/theme";

const useStyles = makeStyles(() =>
  createStyles({
    menuItemStyle: {
      "&:hover": {
        backgroundColor: paletteColors.primary,
        color: "#FFF",
      },
      letterSpacing: "0.25px",
      fontSize: "0.9rem",
    },
    buttonStyle: {
      margin: 0,
    },
  }),
);

export interface IItem {
  action: () => void;
  name: string;
  disabled?: boolean;
}

interface IModalMenu {
  items: IItem[];
  buttonVariation?: boolean;
  buttonProps?: ButtonProps;
  buttonIcon?: any;
  buttonText?: string;
  menuItemClassName?: string;
  menuVert?: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

const ModalMenu: React.FC<IModalMenu> = ({
  items,
  buttonVariation,
  buttonProps,
  buttonText,
  buttonIcon,
  menuItemClassName,
  menuVert = false,
  onClose,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const styles = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuOpen = () => {
    onClick?.();
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: IItem) => {
    return (): void => {
      item.action();
      setAnchorEl(null);
      onClose?.();
    };
  };
  return (
    <div>
      {buttonVariation ? (
        <Button
          {...buttonProps}
          onClick={handleClick}
          className={`${styles.buttonStyle} ${buttonProps?.className}`}
        >
          {buttonIcon ? buttonIcon : null}
          {buttonText}
        </Button>
      ) : (
        <Box>
          <IconButton color="primary" size="small" onClick={handleClick}>
            {menuVert ? <MoreVert sx={{ color: "black" }} /> : <MoreHoriz />}
          </IconButton>
        </Box>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleMenuOpen}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { marginTop: 5, borderRadius: "3px" },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index.toString()}
            onClick={handleMenuItemClick(item)}
            className={`${styles.menuItemStyle} ${menuItemClassName}`}
            disabled={item.disabled}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default ModalMenu;
