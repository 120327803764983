import { CHANGE_HEADCOUNT, CHANGE_STATUS_TRIGGER_EVENT, SET_DATA_EVENT_IN_PROGRESS, STOP_EVENT, UPDATE_HEADCOUNT_FAILURE, UPDATE_HEADCOUNT_START, UPDATE_HEADCOUNT_SUCCESS } from "../actions/triggerEvent";

export interface IDataEvent {
  startData: string;
  expectedHeadCount: number;
  eventType: string;
  emergencyType: string;
  locations: ILocationEvent[];
}
export interface ILocationEvent {
  additionalStudents: string[];
  missingStudents: string[];
  locationName: string;
  additionalCount: number;
  assignedTeacher: string;
  assignedTeacherId: string;
  headCount: number;
  missingCount: number;
  teacherRole: string;
  status: string;
}

export interface ITrigerEventState {
  started: boolean
  data: IDataEvent
}

const initialState: ITrigerEventState = {
  started: false,
  data: {
    emergencyType: "",
    eventType: "",
    expectedHeadCount: 0,
    locations: [],
    startData: new Date().toISOString(),
  },
}


export const TriggerEventReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_STATUS_TRIGGER_EVENT:
      return {
        ...state,
        started: action.payload,
      };
    case STOP_EVENT: 
      return {
        ...state,
        started: false,
      }
    case SET_DATA_EVENT_IN_PROGRESS:
      return {
        ...state,
        data: {...action.payload},
      }
    case CHANGE_HEADCOUNT:
        return {
          ...state,
          data: {
            ...state.data,
            expectedHeadCount: action.payload,
          },
        }
    default:
      return state;
  }
};
