// --- UI DEPENDENCIES
import { Toolbar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// --- DEPENDENCIES
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// --- COMPONENTS
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu } from "../../components/DataGrid";
import MainLayout from "../../components/MainLayout";
// --- REDUX
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
// --- SERVICE
import { changeActiveState, fetchUsers } from "../../services/users";
import districtColumns from "./config/columns";
// --- CONST
import { IUsers } from "../../models/Users";
import { parseSchools, rolesFilter } from "./config/filterOptions";
import { ISchool } from "../../models/School";
import { fetchSchoolsPaginated } from "../../services/schools";
import { roles, rolesNames } from "../../constants";

const UsersDistrictAdmin = () => {
  // --- HOOKS / STATES / SELECTORS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const history = useHistory();
  const [search, setSearch] = useState<string>("");
  const [localRole, setRole] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IUsers[]>([]);

  useEffect(() => {
    loadSchools();
  }, []);

  // --- MAIN METHODS
  // -----------------------MAIN METHODS
  async function loadSchools() {
    try {
      setIsLoading(true);
      const reqSchools = await fetchSchoolsPaginated();
      const schools = parseSchools(reqSchools?.data, user?.districts);
      setIsLoading(false);
      await initState(schools);
    } catch (error) {
      history.goBack();
    }
  }
  async function initState(schools: ISchool[]): Promise<void> {
    setIsLoading(true);
    const data = await fetchUsers();
    const payload = data?.data?.payload;
    const parsedUsers = parseUsers(schools, payload);
    setUsers(parsedUsers);
    setIsLoading(false);
  }

  async function handleDelete(_id: string, disabled: boolean) {
    await changeActiveState({ _id, disabled });
    const copyData = [...users];
    const objIndex = copyData.findIndex((user) => user._id === _id);
    copyData[objIndex].disabled = disabled;
    setUsers(copyData);
  }

  function handleOpenModal(isActivate: boolean, id: string) {
    const activationType = isActivate ? "ACTIVATE" : "INACTIVE";
    const modalConfig: PayloadOpenModal = {
      title: "Confirm",
      description: `Would you like to ${activationType} the user?`,
      action: () => {
        handleDelete(id, !isActivate);
      },
    };
    dispatch(openModal(modalConfig));
  }

  function renderModalMenu(id: string, isActivate: boolean) {
    const detailsOptionsList = [
      {
        name: isActivate ? "ACTIVATE user" : "INACTIVE user",
        action: () => handleOpenModal(isActivate, id),
      },
      {
        name: "Edit user",
        action: () => history.push(`/update-user-superadmin/${id}`),
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  }

  const actionsColumn: GridColDef = {
    headerName: "ACTIONS",
    field: "",
    hideSortIcons: true,
    renderCell: (data) => {
      return renderModalMenu(data.row._id, data.row.disabled);
    },
  };

  const handleFilter = () =>
    users
      ?.filter(
        (user) =>
          user.fullName.toLowerCase().includes(search.toLowerCase()) || !search,
      )
      .filter(
        (user) =>
          user?.role?.name?.includes(localRole) || localRole === "All Roles",
      );

  // -----------------------AUX METHODS

  function parseUsers(schoolsList: ISchool[], userList: IUsers[]) {
    return [...userList].filter((user) => {
      const validSchool =
        [...schoolsList].filter((school) => school?._id === user?.school?._id)
          .length === 1;
      console.log(schoolsList);
      return validSchool;
    });
  }
  // --- RENDER
  return (
    <>
      <Toolbar />
      <PageTitle title="Users" />
      <MainLayout>
        <DataGrid
          columns={districtColumns(actionsColumn)}
          rows={handleFilter()}
          getRowId={(row) => row._id}
          loading={isLoading}
          autoHeight
          toolbarProps={{
            buttonText: "ADD USER",
            filterOptions: rolesFilter,
            buttonAction: () => history.push("create-user-superadmin"),
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              const selectedRoleCode = Object.keys(rolesNames).filter(
                (key) => rolesNames[key] === event.target.value,
              )[0];
              const oldRoleName = Object.keys(roles).filter(
                (key) => roles[key] === selectedRoleCode,
              )[0];
              setRole((oldRoleName as string) || "");
            },
          }}
        />
      </MainLayout>
    </>
  );
};

export default UsersDistrictAdmin;
