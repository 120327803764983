import { ILoggedUser } from "../../models/User";
import {
  ADD_DISTRICT_TO_USER,
  CHANGE_VALUE_EVENT_PROGRESS,
} from "../actions/LoggedUser";
import { STOP_EVENT } from "../actions/triggerEvent";

export enum ACTION_TYPES {
  LOGIN = "LOGIN",
  LOGIN_FAILED = "LOGIN_FAILED",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGOUT = "LOGOUT",
  RESET = "RESET",
  DISABLE_APP_LOADER = "DISABLE_APP_LOADER",
  CHANGE_VALUE_ERROR = "CHANGE_VALUE_ERROR",
}

export interface ILoggedUserReducer {
  loggedUser: ILoggedUser;
  initialAppLoading: boolean;
  loading: boolean;
  error: boolean;
  loginSuccess: boolean;
}

const initialState: ILoggedUserReducer = {
  loggedUser: {
    user: {
      _id: "",
      firstName: "",
      lastName: "",
      role: {
        code: "",
        name: "",
        resource: [],
      },
      disabled: false,
    },
  },
  initialAppLoading: true,
  loading: false,
  error: false,
  loginSuccess: false,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN:
      return {
        ...state,
        loading: true,
        error: false,
        loginSuccess: false,
      };
    case ACTION_TYPES.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        initialAppLoading: false,
        error: action.data,
        loginSuccess: false,
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        initialAppLoading: false,
        error: false,
        loggedUser: action.data,
        loginSuccess: true,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...state,
        loggedUser: null,
        loginSuccess: false,
        loading: false,
        initialAppLoading: false,
      };
    case ACTION_TYPES.DISABLE_APP_LOADER:
      return {
        ...state,
        loading: false,
        initialAppLoading: false,
      };

    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ACTION_TYPES.CHANGE_VALUE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case STOP_EVENT:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          user: {
            ...state.loggedUser.user,
            school: {
              ...(state.loggedUser.user.school as any),
              runningEvent: undefined,
            },
          },
        },
      };
    case CHANGE_VALUE_EVENT_PROGRESS:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          user: {
            ...state.loggedUser.user,
            school: {
              ...(state.loggedUser.user.school as any),
              runningEvent: action.payload,
            },
          },
        },
      };
    case ADD_DISTRICT_TO_USER:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          user: {
            ...state.loggedUser.user,
            districts: [...action.payload],
          },
        },
      };
    default:
      return state;
  }
}
