import { paletteColors, theme } from "../../../../styles/theme";

export const modalStyles = {
  dialogTitle: {
    "& h2": {
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        fontSize: "1.25rem",
      },
      fontSize: "1rem",
      fontWeight: 600,
    },
  },
  dialogContainer: {
    backgroundColor: "red",
  },
  rowContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  dialogButtonsWrapper: {
    padding: "12px 24px",
  },
  loader: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    [theme.breakpoints.down("md")]: {
      maxWidth: 280,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      maxWidth: 450,
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: 600,
    },
  },
  modalForm: {
    paddingBottom: 28,
  
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
     
      paddingBottom: 0,
    },
  },
  inputStaticHeight: {
    marginBottom: "40px",
    maxHeight: "3rem",
  },
  hideInput: {
    visibility: "hidden",
  },
};
