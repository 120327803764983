import { CometChat } from "@cometchat-pro/chat";
import React, { useEffect, useState } from "react";
import { UNIQUE_LISTENER_ID } from "../constants";

const sumValues = (value: any): number => {
  let finalValue = 0;
  for (const key in value) {
    finalValue += value[key];
  }
  return finalValue;
};

const useMessageListener = () => {
  const [Messages, setMessages] = useState<number>(0);

  useEffect(() => {
    sumMessages();
  }, []);

  useEffect(() => {
    CometChat.addMessageListener(
      UNIQUE_LISTENER_ID,
      new CometChat.MessageListener({
        onTextMessageReceived: async (textMessage: CometChat.TextMessage) => {
          sumMessages();
        },
        onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {
          console.log("Message Read", messageReceipt);
      },
      }),
    );

    return () => {
      CometChat.removeMessageListener(UNIQUE_LISTENER_ID);
    };
  }, []);

  async function sumMessages() {
    const numberMessages = (await CometChat.getUnreadMessageCount()) as any;
    const users = sumValues(numberMessages.users);
    const groups = sumValues(numberMessages.groups);
    setMessages(users + groups);
  }

  return Messages;
};

export default useMessageListener;
