import * as yup from "yup";
import { roles } from "../../../constants";

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be only numbers";
const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

/**
 * Yup Schema wich describe the data constrains of an specifc object
 */
export const userValidationSchema = yup.object().shape({
  email: yup
    .string()
    .typeError(IS_STRING)
    .email()
    .required(REQUIRED_MESSAGE("email")),
  firstName: yup
    .string()
    .typeError(IS_STRING)
    .min(1)
    .required(REQUIRED_MESSAGE("firstName")),
  lastName: yup
    .string()
    .typeError(IS_STRING)
    .min(1)
    .required(REQUIRED_MESSAGE("lastName")),
  password: yup
    .string()
    .typeError(IS_STRING)
    .min(8)
    .required(REQUIRED_MESSAGE("password")),
  phoneNumber: yup
    .string()
    .typeError(IS_NUMBER)
    .matches(/^\d{10}$/, 'Phone must contains 10 numbers')
    .required(REQUIRED_MESSAGE("phone")),
  confirmPassword: yup
    .string()
    .defined()
    .oneOf([yup.ref("password")], "Password must be the same"),
  role: yup
    .string()
    .typeError(IS_STRING)
    .oneOf(Object.keys(roles))
    .required(REQUIRED_MESSAGE("role")),
  school: yup.object().optional(),
  districts: yup.array().optional(),
});
