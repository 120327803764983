import { Typography, Box } from "@mui/material";
import { VFC } from "react";

import styles from "./styles";

interface IFooter {
  path?: string;
}

const Footer: VFC<IFooter> = ({ path }) => {
  return (
    <Box sx={{
      ...styles.footer,
      // Media query condition to fix footer on the register screen
      ...(path === "/register" && styles.footerLargeScreen),
    }}>
      <Typography sx={styles.text}>
        Copyright {new Date().getFullYear()} SAFEtech. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
