import axios from '../../config/axios';
import { ACTION_TYPES } from '../reducers/register';

/* REGISTER a new user */
export const register = () => ({ type: ACTION_TYPES.REGISTER });
export const registerFailed = (error: any) => ({
  type: ACTION_TYPES.REGISTER_FAILED,
  data: error,
});
export const registerSuccess = () => ({ type: ACTION_TYPES.REGISTER_SUCCESS });

export function registerUser(userData: any, onRegister: () => void) {
  return async (dispatch: any) => {
    try {
      dispatch(register());

      const response = await axios.post('/register', { ...userData });

      if (response.status === 200) {
        dispatch(registerSuccess());
        onRegister();
      } else {
        dispatch(registerFailed(response.data));
      }
    } catch (error) {
      const err = error as any;
      dispatch(registerFailed(err.data.payload));
    }
  };
}
