import { Grid, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { confirmEmail } from "../../services/login";
import { resetPasswordRequest } from "../../services/resetPassword";

const useStyles = makeStyles((theme) => ({
  generalContainer: {
    paddingTop: "80px",
  },
  description: {
    textAlign: "center",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#5D5D5D",
    marginBottom: "50px",
    fontWeight: "bold",
  },
  returnToLoginOptions: {
    fontSize: "0.8rem",
    textDecoration: "none",
  },
  blueReturnToLoginButton: {
    fontWeight: "bold",
    cursor: "pointer",
    color: "#2B80FF",
  },
}));

interface IParams {
  id: string | string[];
  token: string | string[];
}

interface IConfirmEmailProps {
  params: IParams;
}
/**
 * Pantalla para mostrar después de que el usuario confirme su cuenta a través del link de comprobación
 * @param {IParams}params EL id y token relacionado a la confirmación de cuenta
 */
const ConfirmEmail: React.FC<IConfirmEmailProps> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const {
    params: { id, token },
  } = props;

  const confirmEmailVerification = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await confirmEmail(token as string, id as string);

      if (response.data.success) {
        try {
          await resetPasswordRequest(
            response.data.payload.email as unknown as string,
          );
        } catch (error) {
          console.log("error", error);
        }
        setMessage(
          "The account has been activated successfully. Shortly you will receive an email with instructions to create a password which you can use to log into the application",
        );
      } else {
        setMessage(
          "The account could not be activated. Please contact an administrator.",
        );
      }
    } catch (error) {
      setMessage(
        "The account could not be activated. Please contact an administrator.",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    confirmEmailVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturnToLogin = (): void => {
    history.push("/login");
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <div className={classes.generalContainer}>
        {loading ? (
          <Typography align="center" className={classes.description}>
            Verifying...
          </Typography>
        ) : (
          <Grid item xs={12} container justifyContent="center">
            <Typography
              align="center"
              variant="body2"
              className={classes.description}
            >
              {message}
            </Typography>
            <Link
              onClick={() => {
                handleReturnToLogin();
              }}
              underline="none"
              className={`${classes.returnToLoginOptions} 
                ${classes.blueReturnToLoginButton}`}
            >
              <div style={{ paddingTop: "0.6rem" }}>Go back to login</div>
            </Link>
          </Grid>
        )}
      </div>
    </Grid>
  );
};

export default ConfirmEmail;
