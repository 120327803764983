// ---Dependencies
import { FormikHelpers, useFormik } from "formik";
// ---Typpes
import { IUserForm } from "../../../models/Users";
import { generate } from "../../../utils/helpers/valueGenerators";
// ---Config
import { userValidationSchema } from "./yupSchema";

const pregenPassword = generate.randomString(8)

/**
 * useFormikConfig customhook: Generates the formik schema
 * @param {(values: IUserForm)=> Promise<void>} onSubmit - On submit method recived from react component
 * @returns {FormikSchema}
 */
export function useFormikConfig(
  onSubmit: (
    values: IUserForm,
    formikHelpers: FormikHelpers<IUserForm>,
  ) => Promise<void>,
) {
  return useFormik<IUserForm>({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: undefined,
      password: pregenPassword,
      confirmPassword: pregenPassword,
      role: undefined,
      school: undefined,
      districts: [],
    },
    validationSchema: userValidationSchema,
    onSubmit,
  });
}
