// --- UI DEPENDENCIES
import { Toolbar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// --- DEPENDENCIES
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// --- COMPONENTS
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu } from "../../components/DataGrid";
import MainLayout from "../../components/MainLayout";
// --- REDUX
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
// --- SERVICE
import { deleteDistrictById, fetchDistricts } from "../../services/district";
import districtColumns from "./config/columns";
// --- CONST
import { STATES_USA_FILTER } from "../../constants";
import { IDistrict } from "../../models/District";
import { makeStyles } from "@mui/styles";
import { ERoles } from "../routes/role-catalog";

const useStyles = makeStyles({
  disableRow: {
    background: "#f2f2f2",
  },
});
const Districts = () => {
  const styles = useStyles();

  // --- HOOKS / STATES / SELECTORS
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [districts, setDistricts] = useState<IDistrict[]>([]);

  useEffect(() => {
    initState();
  }, []);

  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  // --- MAIN METHODS

  async function initState(): Promise<void> {
    try {
      setIsLoading(true);
      const { data } = await fetchDistricts();
      setDistricts(data);
      setIsLoading(false);
    } catch (error) {
      history.goBack();
    }
  }

  async function handleDelete(id: string, status: string) {
    await deleteDistrictById(id);
    dispatch(
      openModal({
        title: "Confirm",
        description: `District ${status}D Successfully`,
        hideSecondaryButton: true,
        primaryButtonText: "ACCEPT",
      }),
    );
    const copyData = [...districts];
    const objIndex = copyData.findIndex((district) => district._id === id);
    copyData[objIndex].disabled = !copyData[objIndex].disabled;
    setDistricts(copyData);
  }

  function handleOpenModal(isActivate: boolean, id: string) {
    const activationType = isActivate ? "ACTIVATE" : "DEACTIVATE";
    const modalConfig: PayloadOpenModal = {
      title: "Confirm",
      description: `Would you like to ${activationType} the District?`,
      action: () => {
        handleDelete(id, activationType);
      },
    };
    dispatch(openModal(modalConfig));
  }

  function renderModalMenu(id: string, isActivate: boolean) {
    const detailsOptionsList = [
      {
        name: isActivate ? "ACTIVATE District" : "DEACTIVATE District",
        action: () => handleOpenModal(isActivate, id),
      },
      {
        name: "EDIT District",
        action: () => history.push(`/update-district/${id}`),
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  }

  const actionsColumn: GridColDef = {
    headerName: "ACTIONS",
    field: "",
    hideSortIcons: true,
    renderCell: (data) => {
      return renderModalMenu(data.row._id, data.row.disabled);
    },
  };

  const handleFilter = () =>
    districts
      .filter(
        (district) =>
          district.name.toLowerCase().includes(search.toLowerCase()) || !search,
      )
      .filter(
        (district) =>
          district.state.includes(state) || state.includes("ALL STATES"),
      );

  // --- RENDER
  return (
    <>
      <Toolbar />
      <PageTitle title="Districts" />
      <MainLayout>
        <DataGrid
          columns={districtColumns(actionsColumn)}
          rows={handleFilter()}
          getRowId={(row) => row._id}
          getRowClassName={({ row }) => (row.disabled ? styles.disableRow : "")}
          loading={isLoading}
          autoHeight
          toolbarProps={{
            buttonText:
              (user?.role?.code as ERoles) !== ERoles.RL001
                ? "ADD DISTRICT"
                : undefined,
            filterOptions: STATES_USA_FILTER.map((state) => ({
              name: state,
              value: state,
            })),
            buttonAction: () => history.push("create-district"),
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              setState(event.target.value as string);
            },
          }}
        />
      </MainLayout>
    </>
  );
};

export default Districts;
