import { Add, AddAlert } from "@mui/icons-material/";
import Button, { ButtonProps } from "@mui/material/Button";

// Define a map of available icons
const iconMap = {
  add: <Add />,
  addAlert: <AddAlert />,
};
export interface IButtonProps extends ButtonProps {
  name: keyof typeof iconMap;
  label: string;
  iconPosition: string;
}

export const ButtonIcon: React.FC<IButtonProps> = ({
  name,
  iconPosition,
  label,
  ...rest
}) => {
  const iconElement = iconMap[name];
  return (
    <Button
      startIcon={iconPosition === "start" ? iconElement : null}
      endIcon={iconPosition === "end" ? iconElement : null}
      {...rest}
    >
      {label}
    </Button>
  );
};
