import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { useSelector } from "../../../redux/typedHooks";
import { ItemGrid } from "./CustomCard";

const MissingCount: FC = () => {
  const data = useSelector((store) => store.triggerEvent);

  const missingCount = data.data.locations.reduce(
    (acc: number, next: any) => (acc += next.missingCount),
    0,
  );
  return (
    <ItemGrid
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box>
        <Typography variant="h6" align="left" gutterBottom>
          Missing Count
        </Typography>
        <Typography
          variant="h4"
          align="left"
          gutterBottom
          sx={{ color: "red" }}
        >
          {missingCount}
        </Typography>
      </Box>
    </ItemGrid>
  );
};

export default MissingCount;
