import { AxiosResponse, AxiosRequestConfig } from "axios";
import { ILocationRegistration } from "../components/LocationForm/schema";

import axios, { getToken } from "../config";
import { ILocationTypeResponse } from "../models/Response";

export const LocationUpdate = async (
  form: ILocationRegistration,
): Promise<AxiosResponse<ILocationTypeResponse>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/locations/edit",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: form,
  };
  return axios(config);
};
export const LocationRegister = async (
  form: ILocationRegistration,
): Promise<AxiosResponse<ILocationTypeResponse>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/locations",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: form,
  };
  return axios(config);
};
export const getLocationById = async (_id: string): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/locations/getById",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { _id },
  };
  return axios(config);
};

export const deleteLocation = (
  id: string,
  active: any,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/locations/active",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { _id: id, active },
  };
  return axios(config);
};
export const fetchLocations = (): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/locations",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
export const fetchLocationsQuery = (query: string): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/locations/query/${query}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
