import { IFieldSchema } from "../../components/Form";

export const registerFieldsSchema: IFieldSchema[] = [
  {
    name: "email",
    validations: {
      maxLength: {
        max: 64,
        errorMessage: "Email cant be more than 64 characters long",
      },
      emailFormat: {
        errorMessage: "Invalid Email",
      },
      required: {
        errorMessage: "Email Required",
      },
    },
    value: "",
  },
  {
    name: "password",
    validations: {
      minLength: {
        errorMessage: "Password must be at least 8 characters long ",
        min: 8,
      },
      required: {
        errorMessage: "Password Required ",
      },
    },
    value: "",
    watch: ["confirmPassword"],
  },
  {
    name: "confirmPassword",
    validations: {
      match: {
        match: "password",
        errorMessage: "Passwords must match",
      },
      required: {
        errorMessage: "Password Required",
      },
    },
    value: "",
    watch: ["password"],
  },
  {
    name: "firstName",
    validations: {
      maxLength: {
        max: 50,
        errorMessage: "Name must not be longer than 50 characters",
      },
      minLength: {
        errorMessage: "Name must be at least 2 characters long",
        min: 2,
      },
      required: {
        errorMessage: "NAme(s) required",
      },
    },
    value: "",
  },
  {
    name: "lastName",
    validations: {
      maxLength: {
        max: 50,
        errorMessage: "Last name must not be longer than 50 characters",
      },
      minLength: {
        errorMessage: "Last name must not be shorter than 2 characters",
        min: 2,
      },
      required: {
        errorMessage: "Last name required",
      },
    },
    value: "",
  },
];