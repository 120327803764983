import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import AccountCreated from "../AccountCreated";
import ConfirmEmail from "../ConfirmEmail";
import ForgotPassword from "../ForgotPassword";
import Login from "../Login/";
import Register from "../Register";
import ResetPassword from "../ResetPassword";
import terms from "../Terms";

const NoSessionRoutes = () => {
  return (
    <Router>
      <Switch>
        {/* System Routes */}
        <Route exact path="/login" component={Login} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/account-created" component={AccountCreated} />
        <Route exact path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPassword}/>
        <Route path="/confirm-email" component={ConfirmEmail} />
        <Route path="/terms" component={terms} />
        {/* Default Route */}
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
};

export default NoSessionRoutes;
