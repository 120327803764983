// ---Dependencies
import React, { useEffect } from "react";
// ---UI Dependencies
import { Card, Button, Grid } from "@mui/material";
import { styled } from "@mui/styles";
// ---Redux
import { useDispatch } from "react-redux";
import { useSelector } from "../../redux/typedHooks";
import { useHistory } from "react-router-dom";
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { ILoggedUserReducer } from "../../redux/reducers/LoggedUser";
// ---Components
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import SchoolForm from "../../components/SchoolForm";
// ---Config
import { ISchoolForm } from "../../components/SchoolForm/ISchoolFormProps";
import { ERoles } from "../routes/role-catalog";
import { useFormikConfig } from "./config/useFormikConfig";
// ---Services/Requests
import { createSchool } from "../../services/schools";
import { useLoading } from "../../hooks/useLoading";

// -----------------------STYLES
export const LayoutContainer = styled(Card)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
}));

/**
 * CreateSchool component: Container of the form and other logic that handles the creation of the user
 * @returns {React.ReactElement} React.ReactElement
 */
const CreateSchool = () => {
  // -----------------------CONSTS, HOOKS, STATES
  const { handleClose, handleOpen } = useLoading();
  const history = useHistory();
  const {
    loggedUser: { user },
  } = useSelector((store) => store.loggedUser as ILoggedUserReducer);
  const dispatch = useDispatch();
  const formik = useFormikConfig(onSubmit);

  useEffect(() => {
    if (user?.role?.code === ERoles.RL001) {
      formik.setFieldValue("district", user?.district);
    }
  }, []);

  // -----------------------MAIN METHODS
  async function onSubmit(values: ISchoolForm): Promise<void> {
    try {
      handleOpen();
      await createSchool(values);
      handleSuccessModal();
    } catch {
      handleErrorModal();
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Success",
      description: `School created successfully`,
      action: () => {
        history.goBack();
      },
      primaryButtonText: "ACCEPT",
      hideSecondaryButton: true,
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Error creating School`,
      primaryButtonText: "ACCEPT",
      hideSecondaryButton: true,
    };
    dispatch(openModal(modalConfig));
  }
  // -----------------------RENDER
  return (
    <>
      <PageTitle title="Create School" />
      <MainLayout>
        <LayoutContainer>
          <SchoolForm formik={formik} />
          <Grid
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            container
            spacing={3}
            justifyContent="flex-end"
          >
            <Button onClick={() => history.goBack()} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              sx={{ marginLeft: "10px" }}
              variant="contained"
            >
              SAVE
            </Button>
          </Grid>
        </LayoutContainer>
      </MainLayout>
    </>
  );
};

export default CreateSchool;
