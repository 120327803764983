import { Grid, Box } from "@mui/material";

import logo from "../../assets/images/Safetechlogo.png";
import banner from "../../assets/images/vision.jpg";
import Footer from "../DashboardLayout/footer";

import styles, { Logo, Banner } from "./styles";

interface ILoginLayout {
  showBanner: boolean;
  path?: string;
  children: React.ReactNode;
}

const LoginLayout: React.FC<ILoginLayout> = ({
  showBanner,
  children,
  path,
}) => {
  return (
    <Grid
      sx={{
        ...styles.container,
        ...(path === "/register" && styles.registerContainer),
      }}
    >
      {showBanner && (
        <Box sx={styles.imageContainer}>
          <Banner src={banner} alt="banner" />
        </Box>
      )}
      <Box sx={styles.cardContainer}>
        <Logo
          src={logo}
          alt="logo"
          largeScreen={path === "/register"} // Prop to handle screen with scroll
        />
        {children}

        <Footer path={path} />
      </Box>
    </Grid>
  );
};

export default LoginLayout;
