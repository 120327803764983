import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { parse } from "query-string";
import { Redirect } from "react-router-dom";

import LoginLayout from "../../components/LoginLayout";
import ResetPasswordForm from "../../components/ResetPasswordForm";

const ResetPassword: React.FC = () => {
  const { token, id, firstTime } = parse(window.location.search);

  if (!token || !id || !firstTime) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginLayout showBanner={false}>
      <Card
        style={{
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardContent>
          <ResetPasswordForm params={{ token, id, firstTime }} />
        </CardContent>
      </Card>
    </LoginLayout>
  );
};

export default ResetPassword;
