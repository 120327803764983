import { AxiosResponse, AxiosRequestConfig } from "axios";

import {
  ISchoolForm,
  ISchoolReq,
} from "../components/SchoolForm/ISchoolFormProps";
import axios, { getToken } from "../config";
import { ISchool } from "../models/School";

export const fetchSchoolsPaginated = (
  page?: number,
  limit?: number,
): Promise<AxiosResponse<ISchool[]>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const fetchSchoolById = (
  id: string,
): Promise<AxiosResponse<ISchool>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const createSchool = (data: ISchoolForm) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school`,
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const updateSchool = (data: ISchoolReq, id: string) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const deleteSchoolById = (
  id: string,
): Promise<AxiosResponse<ISchool>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const getSchoolEventCount = (): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school/count/events`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const getSchoolUserCount = (id: string): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school/count/users`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { _id: id },
  };
  return axios(config);
}
export const getAverageEventDuration = (): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/current-event/avg-event`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    
  };
  return axios(config);
};
export const getAvgSchoolperDistrict=(): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/school/average/school-by-district`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    
  };
  return axios(config);
};

