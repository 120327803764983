import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { useSelector } from "../../../redux/typedHooks";
import { ItemGrid } from "./CustomCard";

const EventGeneralInformation: FC = () => {
  const data = useSelector((store) => store.triggerEvent);

  const eventType = data?.data.eventType;
  const startDate = new Date(data?.data.startData).toDateString();
  const startTime = new Date(data?.data.startData).toLocaleTimeString();
  const emergencyType = data?.data.emergencyType;

  return (
    <ItemGrid>
      <Typography variant="h6" align="left" gutterBottom>
        Event General Information
      </Typography>
      <Box
        sx={{
          paddingTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Box>
          <Typography align="left">Start Date</Typography>
          <Typography variant="subtitle1" align="left">
            {startDate}
          </Typography>
        </Box>
        <Box>
          <Typography align="left">Start Time</Typography>
          <Typography component={"p"} variant="subtitle1" align="left">
            {startTime}
          </Typography>
        </Box>
        <Box>
          <Typography align="left">Event Type</Typography>
          <Typography variant="subtitle1" align="left">
            {eventType}
          </Typography>
        </Box>
        <Box>
          <Typography align="left">Drill / Event</Typography>
          <Typography variant="subtitle1" align="left">
            {emergencyType}
          </Typography>
        </Box>
      </Box>
    </ItemGrid>
  );
};

export default EventGeneralInformation;
