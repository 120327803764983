/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Link,
  TextField,
  CircularProgress
} from "@mui/material";
import React from "react";
import { RouteChildrenProps } from "react-router-dom";

import Form, { IFields } from "../../components/Form";
import LoginLayout from "../../components/LoginLayout";
import { registerUser, reset } from "../../redux/actions";
import { useDispatch, useSelector } from "../../redux/typedHooks";

import { registerFieldsSchema } from "./constants";
import styles from "./styles";

/* TODO: This id has to match the only one that exists on backend by default. 
  This could change in case a role is selectable (like service cards)
 */
const defaultRoleId = process.env.REACT_APP_DEFAULT_ROLE_ID;

const Register: React.FC<RouteChildrenProps> = ({ history }) => {
  const path = history.location.pathname;
  const dispatch = useDispatch();

  const { loading } = useSelector((store) => store.register);

  const onRegister = (): void => {
    history?.replace('/account-created');
  }

  const handleLogin = (): void => {
    history?.push('login');
  };

  const handleRegister = (fields: IFields) => {
    dispatch(registerUser({ ...fields, roleId: defaultRoleId }, onRegister));
  };

  React.useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <LoginLayout showBanner path={path}>
      <Card sx={styles.containerWrapper}>
        <CardContent sx={styles.container}>
          <Form onSubmit={handleRegister} fields={registerFieldsSchema}>
            {({ handleSubmit, handleChange, valid, fields }) => (
              <Grid container direction="column">
                <Typography variant="subtitle1" sx={styles.title}>
                  Registrar Usuario
                </Typography>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <TextField
                    disabled={loading}
                    label="Nombre(s)"
                    value={fields.firstName.value}
                    onChange={handleChange("firstName")}
                    variant="outlined"
                    error={!!fields.firstName.errors.length}
                    helperText={fields.firstName.errors.length > 0
                      ? fields.firstName.errors.join(". ") : "Required"
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <TextField
                    disabled={loading}
                    label="Apellidos"
                    value={fields.lastName.value}
                    onChange={handleChange("lastName")}
                    variant="outlined"
                    error={!!fields.lastName.errors.length}
                    helperText={fields.lastName.errors.length > 0
                      ? fields.lastName.errors.join(". ") : "Required"
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <TextField
                    disabled={loading}
                    label="Email"
                    value={fields.email.value}
                    onChange={handleChange("email")}
                    variant="outlined"
                    error={!!fields.email.errors.length}
                    helperText={fields.email.errors.length > 0
                      ? fields.email.errors.join(". ") : "Required"
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <TextField
                    disabled={loading}
                    label="Contraseña"
                    value={fields.password.value}
                    type="password"
                    onChange={handleChange("password")}
                    variant="outlined"
                    error={!!fields.password.errors.length}
                    helperText={fields.password.errors.length > 0
                      ? fields.password.errors.join(". ") : "Required"
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sx={styles.inputWrapper}>
                  <TextField
                    disabled={loading}
                    label="Confirmar Contraseña"
                    value={fields.confirmPassword.value}
                    type="password"
                    onChange={handleChange("confirmPassword")}
                    variant="outlined"
                    error={!!fields.confirmPassword.errors.length}
                    helperText={fields.confirmPassword.errors.length > 0
                      ? fields.confirmPassword.errors.join(". ") : "Required"
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sx={styles.buttonWrapper}>
                  {loading ? (
                    <CircularProgress data-testid="loading-indicator" size={36} />
                    ) : (
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="primary"
                      sx={styles.button}
                    >
                      Create Account
                    </Button>
                  )}
                </Grid>
                <Typography sx={styles.forgotAccount}>
                  ¿Ya tienes usuario?
                  <Link
                    onClick={handleLogin}
                    underline="none"
                    sx={styles.signUp}
                  >
                    Inicia sesión
                  </Link>
                </Typography>
              </Grid>
            )}
          </Form>
        </CardContent>
      </Card>
    </LoginLayout>
  );
};

export default Register;
