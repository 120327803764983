import React, { FC } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";

import { v4 as uuid } from "uuid";
import { IDataGridHeadCount } from "../interfaces";
import { useStylesDataGrid } from "../styles";

const DataGridHeadCount: FC<IDataGridHeadCount> = (props) => {
  const { additional, missing, handleDelete } = props;
  const styles = useStylesDataGrid();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      headerName: "ACTIONS",
      field: "",
      hideSortIcons: true,
      renderCell: ({ row }) => {
        return (
          <Button
            style={{
              backgroundColor: "#e8605d",
              padding: "3px 35px",
            }}
            onClick={() => handleDelete({ name: row.name, status: row.status })}
            variant="contained"
            color="primary"
            type="submit"
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const additionalStudents = additional.map((i) => ({
    id: uuid(),
    name: i,
    status: "additional",
  }));

  const missingStudents = missing.map((i) => ({
    id: uuid(),
    name: i,
    status: "missing",
  }));

  return (
    <Box sx={{ height: 300, width: "100%" }}>
      <DataGrid
        className={styles.root}
        rows={[...additionalStudents, ...missingStudents]}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </Box>
  );
};

export default DataGridHeadCount;
