import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, SelectChangeEvent, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from "@mui/x-data-grid";
import { VFC, useState, ChangeEvent } from "react";

import { ISelectOption } from "../../Inputs/Select";
import { Select } from "../../Inputs/index";

import styles from "./styles";

interface ICustomToolbarProps {
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch?: () => void;
  filterOptions: ISelectOption[];
  onFilterChange: (event: SelectChangeEvent<unknown>) => void;
  buttonText: string;
  buttonAction?: () => void;
  hideFilters?: boolean;
  filterLabel?: string;
  hideIcons?: boolean;
  filterField?: string;
  fileName?: string;
}
/**
 * Barra de Herramientas ajustable para el datagrid 
 * @param {boolean} hideFilters Esconder los filtros de la barra de herramientas
   @param { (event: React.ChangeEvent<HTMLInputElement>) => void}  onSearchChange Función para manejar el cambio al ingresar algo a la busqueda
   @param {() => void}  clearSearch Borrar la busqueda
   @param {string}  buttonText El texto para el botón dentro de la barra
   @param { () => void}  buttonAction Acción del botón de la barra de herramientas
   @param {ISelectOption[]}  filterOptions Las opciones por las que se hará el filtro de las filas en la tabla
   @param {(event: SelectChangeEvent<unknown>) => void}  onFilterChange Función para ejecutar al aplicar un filtro
   @param {string}  filterLabel La etiqueta del filtro
   @param {string}  filterField El campo a filtrar 
 */

const CustomToolbar: VFC<ICustomToolbarProps> = (props) => {
  const {
    hideFilters,
    onSearchChange,
    clearSearch,
    buttonText,
    buttonAction,
    filterOptions,
    onFilterChange,
    filterLabel,
    filterField,
    fileName,
    hideIcons,
  } = props;
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  
  const smallScreen = window.screen.width < theme.breakpoints.values["md"];

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    onSearchChange?.(event);
  };

  const handleSearchClear = () => {
    setSearch("");
    clearSearch?.();
  };

  const handleFilterChange = (event: SelectChangeEvent<unknown>) => {
    const value = String(event.target.value);
    onFilterChange?.(event);
    setFilter(value);
    if (filterField) {
      setTableFilter(filterField, value);
    }
  };

  const setTableFilter = (columnField: string, value: string) => {
    apiRef.current.setFilterModel({
      items: [
        {
          columnField,
          operatorValue: "contains",
          value,
        },
      ],
    });
  };

  return (
    <Box sx={styles.toolbar}>
      <TextField
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        placeholder="Search…"
        sx={styles.searchField}
        InputProps={{
          startAdornment: (
            <SearchIcon fontSize="small" sx={styles.searchIcon} />
          ),
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: search.length > 0 ? "visible" : "hidden" }}
              onClick={handleSearchClear}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      {!hideFilters && (
        <Box sx={styles.selectorWrapper}>
          <Select
            sx={styles.selector}
            variant="outlined"
            value={filter}
            label={filterLabel}
            onChange={handleFilterChange}
            options={filterOptions}
            style={{ width: smallScreen ? "100%" : undefined }}
          />
        </Box>
      )}

      <Box sx={styles.optionsWrapper}>
        {(!hideIcons ?? !smallScreen ) && (
          <Box sx={styles.iconsWrapper}>
            <GridToolbarExport
              csvOptions={{ fileName: fileName ? fileName : "report" }}
              sx={styles.icon}
            />
            <GridToolbarDensitySelector sx={styles.icon} />
          </Box>
        )}
        {buttonText && (
          <Button
            variant="contained"
            title={buttonText}
            sx={styles.button}
            onClick={buttonAction}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};
export default CustomToolbar;
