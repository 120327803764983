import { AxiosResponse, AxiosRequestConfig } from "axios";
import { IDistrictForm } from "../components/DistrictForm/IDistrictFormProps";

import axios, { getToken } from "../config";
import { IDistrict } from "../models/District";

export const fetchDistricts = (
  query?: string,
): Promise<AxiosResponse<IDistrict[]>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/district${query ? query : ""}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const fetchDistrictById = (
  id: string,
): Promise<AxiosResponse<IDistrict>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/district/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const createDistrict = (data: IDistrictForm) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/district`,
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const updateDistrict = (data: IDistrictForm, id: string) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/district/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const deleteDistrictById = (
  id: string,
): Promise<AxiosResponse<IDistrict>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/district/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
