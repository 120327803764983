import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  CircularProgress,
  TextField,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { paletteColors } from "../../../styles/theme";

const useStyles = makeStyles({
  dialogTitle: {
    "& h2": {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
  },
  dialogBody: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  dialogButtonsWrapper: {
    padding: "15px",
  },
  loader: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
  },
  acceptButton: {
    backgroundColor: paletteColors.error,
    color: "#FFF",
    "&:hover": {
      backgroundColor: paletteColors.errorSecondary,
    },
  },
});

interface IConfirmationModal {
  open: boolean;
  title: string;
  confirmButtonAction: (arg0: number | undefined) => () => void;
  cancelButtonAction?: () => void;
  confirmButtonLoading?: boolean;
  originalHeadCount: number | undefined ;
}

const StopEventDialog: React.FC<IConfirmationModal> = ({
  open,
  title,
  confirmButtonAction,
  cancelButtonAction,
  confirmButtonLoading,
  originalHeadCount,
}) => {
  const [headCount, setHeadCount] = useState(originalHeadCount);
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadCount(parseInt(event.target.value) || undefined);
  };
  return (
    <Dialog open={open} aria-labelledby="dialog-modal">
      <DialogTitle sx={{ px: 8, py: 6, pb: 2 }} className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <Box sx={{ px: 8, pb: 2 }}>
        <TextField
          id="head-count"
          label="Head Count"
          variant="outlined"
          required={false}
          fullWidth
          value={headCount}
          onChange={handleChange}
        />
      </Box>
      <DialogActions className={classes.dialogButtonsWrapper}>
        <Button color="primary" onClick={cancelButtonAction} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={confirmButtonAction(headCount)}
          disabled={confirmButtonLoading}
          variant="contained"
          className={classes.acceptButton}
        >
          Yes
          {confirmButtonLoading && (
            <CircularProgress className={classes.loader} size={24} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopEventDialog;
