import { AxiosResponse, AxiosRequestConfig } from "axios";
import axios, { getToken } from "../config";
import { IAlertEvent } from "../models/AlertEvent";


export const addSilentAlertEvent = async (
  form: IAlertEvent,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event/create-alert",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: form,
  };
  return axios(config);
};