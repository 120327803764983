// --- DEPENDENCIES
import { Button, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
// --- COMPONENTS
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import DistrictForm from "../../components/DistrictForm";
import { LayoutContainer } from "../CreateSchool/CreateSchool";
// --- REDUX
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { createDistrict } from "../../services/district";
// --- CONFIG
import { useFormikConfig } from "./config/useFormikConfig";
import { IDistrictForm } from "../../components/DistrictForm/IDistrictFormProps";
import { useLoading } from "../../hooks/useLoading";
import { addDistrictToUser } from "../../redux/actions/LoggedUser";

const CreateDistrict = () => {
  const { handleClose, handleOpen } = useLoading();
  // --- CONST / HOOKS
  const history = useHistory();
  const dispatch = useDispatch();
  const formik = useFormikConfig(onSubmit);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  // --- MAIN METHODS
  async function onSubmit(values: IDistrictForm): Promise<void> {
    try {
      handleOpen();
      const { data } = await createDistrict(values);
      const newDistricts = [...user?.districts, data];
      dispatch(addDistrictToUser(newDistricts));
      handleSuccessModal();
    } catch {
      handleErrorModal();
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Success",
      description: `District created successfully`,
      action: () => history.goBack(),
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Error creating District`,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  return (
    <>
      <PageTitle title="Create disctrict" />
      <MainLayout>
        <LayoutContainer>
          <DistrictForm formik={formik} />
          <Grid
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            container
            spacing={3}
            justifyContent="flex-end"
          >
            <Button onClick={() => history.goBack()} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              sx={{ marginLeft: "10px" }}
              variant="contained"
            >
              SAVE
            </Button>
          </Grid>
        </LayoutContainer>
      </MainLayout>
    </>
  );
};

export default CreateDistrict;
