// ---Dependencies
import { Autocomplete, TextField } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { ISchool } from "../../../../../models/School";
import { IUpdateUserFormProps } from "../../../../../models/Users";
// ---Types
import { ICatalogs } from "../../../config/useCatalogs";
// ---Components
// ---UI Dependencies
// ---Custom Hooks
// ---Redux
// ---Config
// ---Assets
// ---Utils
// ---Constants
// ---Services/Requests
// ---Images

interface Props extends IUpdateUserFormProps {
  localRole?: string | null;
  schools: ICatalogs["schools"];
}

interface AutocompleteOption {
  name: string;
  value: string;
  zipCode: string;
}
/**
 * SchoolsInput Component: Autocomplete Input field that map the
 * schools in the options along their zip code and save the selected option
 * @param {Props} props - Formik object and list of schools
 * @returns {ReactElement}
 */
export function SchoolsInput({ formik, schools }: Props): ReactElement {
  const [currentSchool, setCurrentSchool] = useState<AutocompleteOption>({
    name: formik.values.school?.name || "",
    value: formik.values.school?._id || "",
    zipCode: formik.values.school?.zipCode || "",
  });

  const filteredSchoolValues: AutocompleteOption[] = schools.map((school) => {
    const autocompleteSchool = {
      name: school.name,
      value: school._id,
      zipCode: school.zipCode,
    };
    return autocompleteSchool;
  });

  function onChangeAutoComp(_: any, selectedSchool: AutocompleteOption | null) {
    const school = schools.find(
      (school) => school._id === selectedSchool?.value,
    );
    formik.setFieldValue("school", school);
    setCurrentSchool({
      name: school?.name || "",
      value: school?._id || "",
      zipCode: school?.zipCode || "",
    });
  }
  const showError = !formik.values.school;

  return (
    <Autocomplete
      options={filteredSchoolValues}
      value={currentSchool}
      defaultValue={currentSchool}
      onChange={onChangeAutoComp}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option) => {
        return (
          <h5 {...(props as any)}>
            {option?.name} (ZIP Code: {option?.zipCode})
          </h5>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="School*"
          variant="outlined"
          error={showError}
          helperText={showError ? "Select an option" : ""}
        />
      )}
    />
  );
}
