//--------------------- Libraries UI
import Button from "@mui/material/Button";
import DialogAlert from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//---------------------- Dependencies
import ReactDOM from "react-dom";
//---------------------- Redux
import { closeModal } from "../../redux/actions/modal";
import { IModalInitialState } from "../../redux/reducers/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
//---------------------- Interfaces 8yg
export interface IDialogProps {
  open: boolean;
  title?: string;
  description: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hideSecondaryButton?: boolean;
  handleClose: () => void;
  handleAction: () => void;
}

const Dialog = () => {
  //---------------------- HOOKS / CONST
  const dispatch = useDispatch();
  const {
    description,
    action,
    isOpen,
    title,
    hideSecondaryButton,
    primaryButtonText,
    secondaryButtonText,
  } = useSelector((store) => store.modal) as IModalInitialState;

  //---------------------- MAIN METHODS
  function handleCloseModal() {
    dispatch(closeModal);
  }
  function handleActionModal() {
    action();
    handleCloseModal();
  }

  const component = (
    <DialogAlert open={isOpen}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideSecondaryButton && (
          <Button onClick={handleCloseModal}>
            {secondaryButtonText ? secondaryButtonText : "Cancel"}
          </Button>
        )}
        <Button variant="contained" onClick={handleActionModal} autoFocus>
          {primaryButtonText ? primaryButtonText : "Confirm"}
        </Button>
      </DialogActions>
    </DialogAlert>
  );

  return isOpen
    ? ReactDOM.createPortal(
        component,
        document.getElementById("modal") as HTMLElement,
      )
    : null;
};

export default Dialog;
