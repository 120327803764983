import { styled } from "@mui/material/styles";

const styles = {
  container: {
    display: "flex",
    flex: 1,
  },
  title: {
    color: "#595959",
    alignSelf: "center",
    marginBottom: "28px",
    "@media (max-height: 700px)": {
      marginBottom: "18px",
    },
  },
  cardContainer: {
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF 0% 0% no-repeat padding-box",
    height: "100vh",
    maxHeight: "100vh",
    paddingLeft: {
      xs: "28px",
      md: "0px",
    },
    paddingRight: {
      xs: "28px",
      md: "0px",
    },
    "@media (max-width: 365px)": {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
    "@media only screen and (min-width: 500px) and (max-width: 700px)": {
      justifyContent: "center",
      alignItems: "center",
    },
    "@media only screen and (max-height: 650px) and (max-width: 500)": {
      height: "unset",
    },
  },
  input: {
    maxHeight: "56px",
    width: "320px",
    marginBottom: "42px",
    "@media (max-height: 700px)": {
      marginBottom: "35px",
    },
  } as const,
  confirmButton: {
    width: "100%",
    height: "2.3rem",
    textTransform: "none",
    margin: "1rem 0",
  },
} as const;

export default styles;
