// ---Components
import { ButtonIcon } from "../../components/ButtonWithIcon";
import { displayToast } from "../../utils/helpers/displayToast";
import { addSilentAlertEvent } from "../../services/alertEvent";
// ---Redux
import { useSelector } from "../../redux/typedHooks";
//Custom Hooks
import { useLoading } from "../../hooks/useLoading";

import { IButtonProps } from "../../components/ButtonWithIcon/ButtonIcon";

const PanicButton = ({ ...rest }: IButtonProps) => {
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const { handleClose, handleOpen } = useLoading();

  async function handleSubmit() {
    try {
      handleOpen();
      const dataEvent = {
        school: user?.school,
        role: user?.role,
        id: user?._id,
        username: `${user?.firstName} ${user?.lastName}`,
      };
      await addSilentAlertEvent(dataEvent);

      displayToast("success", "Silent alerts created successfully");
    } catch {
      displayToast("error", "Error creating Alerts");
    } finally {
      handleClose();
    }
  }
  return <ButtonIcon onClick={handleSubmit} {...rest} />;
};

export default PanicButton;
