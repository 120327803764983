// ---Dependencies
import React from "react";
// ---UI Dependencies
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
  GridValueGetterParams,
} from "@mui/x-data-grid";
// ---Components
import { CustomSort } from "../../../components/DataGrid";

/**
 * Columns and rules for display the data of the DataGrid Table from material ui
 * @param {GridColDef} actionsColumn - Configuration of last column "Actions" that allow the user edit and inactive a specific user
 * @returns {GridColDef[]} GridColDef[] The Columns configuration array
 */
export function columns(actionsColumn: GridColDef): GridColDef[] {
  return [
    {
      headerName: "Schools",
      field: "name",
      hideSortIcons: true,
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Address",
      field: "address",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "District",
      field: "district",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      valueGetter: (param: GridValueGetterParams) => {
        return param.row.district?.name;
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Grade Levels",
      field: "gradeLevels",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      valueGetter: (param: GridValueGetterParams) => {
        return param.row.gradeLevels.join(", ");
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Total Students",
      field: "totalStudentsEnrollment",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Zip Code",
      field: "zipCode",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Status",
      field: "disabled",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      valueGetter: (param: GridValueGetterParams) => {
        return param.row.disabled ? "INACTIVE" : "ACTIVE";
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    actionsColumn,
  ];
}
