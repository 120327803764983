// ---Dependencies
import React, { useEffect, useState } from "react";
// ---UI Dependencies
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
// ---Types
import { IUpdateUserFormProps } from "../../../../models/Users";
// ---Constants
import { roles, rolesNames, schoolRolesNames } from "../../../../constants";

interface Props extends IUpdateUserFormProps {
  isRole?: string;
}
export const RoleInput: React.FC<Props> = ({ formik }) => {
  // -----------------------CONSTS, HOOKS, STATES
  const [wasRole, setWasRole] = useState<string | undefined>(undefined);
  useEffect(() => {
    validPopulation();
  }, [formik.values.role]);
  // -----------------------MAIN METHODS
  function validPopulation() {
    const isRole = formik.values.role;
    if (isRole && !wasRole) {
      setWasRole(isRole);
    }
  }
  function onChange(_: any, value: string | null) {
    const selectedRoleCode = Object.keys(rolesNames).filter(
      (key) => rolesNames[key] === value,
    )[0];
    const oldRoleName = Object.keys(roles).filter(
      (key) => roles[key] === selectedRoleCode,
    )[0];
    formik.setFieldValue("role", oldRoleName);
    formik.setFieldValue("districts", []);
  }
  // -----------------------RENDER
  if (wasRole) {
    return (
      <Autocomplete
        value={rolesNames[roles[formik.values.role || "DISTRICT_ADMIN"]]}
        defaultValue={rolesNames[roles[formik.values.role || "DISTRICT_ADMIN"]]}
        onChange={onChange}
        options={Object.keys(schoolRolesNames).map(
          (key: string) => schoolRolesNames[key],
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            defaultValue={formik.values.role}
            label="Role*"
            variant="outlined"
            error={!!formik.errors.role && !!formik.touched.role}
            helperText={
              !!formik.errors.role &&
              !!formik.touched.role &&
              formik.errors.role
            }
          />
        )}
      />
    );
  }
  return <LoadingSpinner />;
};

function LoadingSpinner() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
}
