import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { DashboardLayout } from "../../components/DashboardLayout";
import { useSocket } from "../../hooks/useSocket";
import routes from "../../pages/routes/routes";
import { useSelector } from "../../redux/typedHooks";

import { ERoles } from "./role-catalog";

const FilterRouter = () => {
  const { socket } = useSocket();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  useEffect(() => {
    joinRoomSocket(user?.school?._id);
  }, [user?.school?._id]);

  useEffect(() => {
    socket?.on("connect", () => {
      joinRoomSocket(user?.school?._id);
    });
  }, [user?.school?._id]);

  function joinRoomSocket(id: string) {
    const localRole = user?.role?.name;
    const isSchoolUser = [
      "TEACHER",
      "SAFETY_TEAM",
      "SAFETY_COMMANDER",
      "ENROLLMENT_LEADER",
    ].includes(String(localRole));

    if (isSchoolUser && id) {
      socket?.emit("join-school", id);
    }
  }

  const listRoutes = routes
    .flatMap((route) => (route.children ? route.children : route))
    .filter((route) => route.roles.includes(user?.role?.code as ERoles));

  return (
    <Router>
      <DashboardLayout>
        <Switch>
          {listRoutes.map((route, index) => (
            <Route
              key={index}
              exact={route.exact}
              path={route.route}
              component={route.component}
            />
          ))}
          <Route path="*" render={() => <Redirect to="/dashboard" />} />
        </Switch>
      </DashboardLayout>
    </Router>
  );
};

export default FilterRouter;
