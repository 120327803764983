import { AxiosError } from "axios";

import { displayToast } from "./displayToast";

export const axiosErrorHandling = (message: string, error: AxiosError) => {
  if (error?.response?.data?.errors) {
    const errors = error.response.data.errors;
    const errorArray = errors.map((error: any) => `- ${error.msg}`);
    displayToast("error", errorArray.join("\n"), undefined, {
      whiteSpace: "pre-line",
    });
  } else if (
    error?.response?.data?.payload &&
    typeof error?.response?.data?.payload === "string"
  ) {
    displayToast("error", error.response.data.payload);
  } else {
    displayToast("error", message);
  }
};
