import React, { FC, useEffect, useMemo, useState } from "react";
import io, { Socket } from "socket.io-client";

export interface IContextSocket {
  socket: Socket | null;
  isConnected: boolean;
}

const Context = React.createContext<IContextSocket | null>(null);

export const SocketProvider: FC = ({ children }) => {
  const [Socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_URL}/`, {
      autoConnect: true,
    });

    socket.on("connect", () => {
      setSocket(socket);
    });

    socket.on("disconnect", () => {
      setSocket(null);
    });
    return () => {
      socket.disconnect()
    };
  }, []);

  const value = useMemo(() => {
    return {
      socket: Socket,
      isConnected: !!Socket?.connected,
    };
  }, [Socket]);

  return (
    <Context.Provider
      value={value}
    >
      {children}
    </Context.Provider>
  );
};
export const useSocket = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error("No esta dentro del contexto");
  }
  return context;
};
