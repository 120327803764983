import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

const resendConfirmationEmail = (
  email: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/resend-confirm/${email}`,
    method: "GET",
  };

  return axios(config);
};

const confirmEmail = (
  token: string,
  id: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/confirm/${id}/${token}`,
    method: "GET",
  };

  return axios(config);
};

export { resendConfirmationEmail, confirmEmail };
