import * as yup from "yup";

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be a number value.";
const IS_POSITIVE_NUMBER = (field: string) =>
  `${field} must be a positive number`;

const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

export const headCountSchema = yup.object().shape({
  school: yup.string().required(REQUIRED_MESSAGE("School")),
  locationId: yup.string().required(REQUIRED_MESSAGE("Location")),
  assignedTeacher: yup.string().required(REQUIRED_MESSAGE("Assigned Teacher")),
  headCount: yup
    .number()
    .min(0, IS_POSITIVE_NUMBER("Head Count"))
    .typeError(IS_NUMBER)
    .required(REQUIRED_MESSAGE("Head Count")),
  missingCount: yup
    .number()
    .typeError(IS_NUMBER)
    .min(0, IS_POSITIVE_NUMBER("Head Count"))
    .typeError(IS_NUMBER)
    .required(REQUIRED_MESSAGE("Missing Count")),
  additionalCount: yup
    .number()
    .typeError(IS_NUMBER)
    .min(0, IS_POSITIVE_NUMBER("Head Count"))
    .typeError(IS_NUMBER)
    .required(REQUIRED_MESSAGE("Additional Count")),
  additionalStudents: yup.array().of(yup.string()),
  missingStudents: yup.array().of(yup.string()),
  notes: yup.string(),
  status: yup.string().required(REQUIRED_MESSAGE("Status")),
});

export const subFormHeadCountSchema = yup.object().shape({
  status: yup.string().required(REQUIRED_MESSAGE("Status")),
  name: yup.string().required(REQUIRED_MESSAGE("Name")),
});
