import { AxiosResponse, AxiosRequestConfig } from "axios";
import { IDistrictForm } from "../components/DistrictForm/IDistrictFormProps";

import axios, { getToken } from "../config";
import { IDistrict } from "../models/District";
import { IUserForm, IUserRequest, IUsers } from "../models/Users";

export const fetchUsers = (
  query?: string,
): Promise<AxiosResponse<{ payload: IUsers[] }>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/superadmin-users${query ? query : ""}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const fetchUsersSafety = (
  school: string,
): Promise<AxiosResponse<{ payload: IUsers[] }>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/commander-users`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      school,
    },
  };
  return axios(config);
};

export const fetchOneUser = (
  id?: string,
): Promise<AxiosResponse<{ payload: IUsers }>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const fetchOneUserAuth = (
  token: string | string[],
  id?: string | string[],
): Promise<AxiosResponse<{ payload: IUsers }>> => {
  const config: AxiosRequestConfig = {
    url: `/users/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const createUser = (data: IUserRequest) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users`,
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const updateUser = (data: IUserRequest) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users`,
    method: "PUT",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const changeActiveState = (data: {
  _id: string;
  disabled: boolean;
}): Promise<AxiosResponse<IDistrict>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users/active-status",
    data,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const changeAlertsState = (data: {
  _id: string;
  alertsEnabled: boolean;
}): Promise<AxiosResponse<IDistrict>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users/activate-alerts",
    data,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
