/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChangeEvent,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { NumberFormatValues } from "react-number-format";

import clabe from "../../utils/validations/clabe";
import curp from "../../utils/validations/curp";
import email from "../../utils/validations/email";
import float from "../../utils/validations/float";
import length from "../../utils/validations/length";
import match from "../../utils/validations/match";
import maxLength from "../../utils/validations/maxLength";
import maxValue from "../../utils/validations/maxValue";
import minLength from "../../utils/validations/minLength";
import minValue from "../../utils/validations/minValue";
import nss from "../../utils/validations/nss";
import numeric from "../../utils/validations/numeric";
import positiveNumber from "../../utils/validations/positiveNumber";
import required from "../../utils/validations/required";
import requiredDate from "../../utils/validations/requiredDate";
import requiredMultiselect from "../../utils/validations/requiredMultiselect";
import requiredSelected from "../../utils/validations/requiredSelected";
import rfc from "../../utils/validations/rfc";
import string from "../../utils/validations/string";

interface ISelectOption {
  value: string | number;
  name: string;
}

interface IFormChildrenCall {
  handleSubmit: () => void;
  highlightRequired: () => void;
  valid: boolean;
  fields: IFields;
  handleChange: (name: string, userValue?: any) => (value: any) => void;
  handleChangeValue: (
    name: string,
    type: keyof NumberFormatValues,
  ) => (value: any) => void;
  handleClear: () => void;
  handleBlur: (name: string) => (value: any) => void;
}

export interface IValidations {
  required?: { errorMessage: string };
  requiredIf?: { field: string; equals: string };
  emailFormat?: { errorMessage: string };
  dateFormat?: { errorMessage: string };
  curp?: { errorMessage: string };
  rfc?: { errorMessage: string };
  nss?: { errorMessage: string };
  positiveNumber?: { errorMessage: string };
  clabe?: { errorMessage: string };
  minLength?: { min: number; errorMessage: string };
  maxLength?: { max: number; errorMessage: string };
  minValue?: { min: number; errorMessage: string };
  maxValue?: { max: number; errorMessage: string };
  match?: { match: string; errorMessage: string };
  string?: { errorMessage: string };
  length?: { length: number; errorMessage: string };
  numeric?: { errorMessage: string };
  float?: { errorMessage: string };
  regex?: { test: RegExp; errorMessage: string };
  boolean?: { errorMessage: string };
}

export interface IFieldSchema {
  name: string;
  value: any;
  validations?: IValidations;
  watch?: string[];
  options?: ISelectOption[];
}

export interface IFormField extends IFieldSchema {
  valid: boolean;
  errors: string[];
}

export interface IFields {
  [key: string]: IFormField;
}

interface IProps {
  children: (props: IFormChildrenCall) => ReactNode;
  onSubmit: (fields: IFields) => void;
  noValidation?: boolean;
  fields: IFieldSchema[];
  initialValues?: {
    [key: string]: any;
  };
  resetForm?: number;
}

const Form: FC<IProps> = (props) => {
  const [valid, setValid] = useState<boolean>(false);
  const [fields, setFields] = useState<IFields>({});

  useEffect(() => {
    createFields();
  }, []);

  useEffect(() => {
    createFields();
  }, [props.resetForm]);

  const createFields = () => {
    const { fields: propFields, initialValues } = props;
    const fields: IFields = {};
    for (const field of propFields) {
      const value = field.value;

      fields[field.name] = {
        ...field,
        errors: [],
        valid: field.validations ? false : true,
        value:
          initialValues?.[field.name] ??
          (field.options ? field.options[0].value : value),
      };

      if (field.validations && field.validations?.required === undefined) {
        validateField(fields[field.name]);
      }

      if (
        !Array.isArray(field.value) &&
        (fields[field.name].value !== "" ||
          field.options !== undefined ||
          fields[field.name].validations?.requiredIf !== undefined)
      ) {
        validateOrSetErrors(fields[field.name], field.name);
      }
    }
    const isFormValid = Object.keys(fields).every(
      (field) => fields[field].valid,
    );
    setValid(isFormValid);
    setFields(fields);
  };

  useEffect(() => {
    if (props.initialValues) {
      createFields();
    }
  }, [props.initialValues]);

  const handleChange =
    (name: string, userValue?: any) =>
    (event: ChangeEvent<HTMLInputElement> | Date | string) => {
      try {
        const input = fields[name];
        let target: any = "";
        if (userValue) {
          input.value = userValue;
        } else if (event instanceof Date) {
          input.value = event;
        } else if (typeof event === "string") {
          //Handle manual value string assignment
          input.value = event;
        } else if (event.target.type === "checkbox") {
          // Handle boolean values - checkboxes
          input.value = event.target.checked;
        } else {
          // Condition to handle native event from MUI v5
          if (event.nativeEvent) {
            target = (event as any).nativeEvent.target.tagName;
          }
          input.value = event.target.value;
        }

        setFields({
          ...fields,
          [name]: {
            ...input,
            value: input.value,
          },
        });
        if (target && target !== "INPUT") {
          validateOrSetErrors(fields[name], name);
        }
      } catch (e) {
        console.error(e);
      }
    };

  const handleClear = () => {
    try {
      const test: any = fields;
      Object.keys(test).forEach((val, index) => {
        test[val]["value"]=""
      });
      setFields({...test});
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeValue =
    (name: string, type: string) => (values: NumberFormatValues) => {
      const value = values[type as keyof typeof values];
      /* setFocus(true) */
      handleChange(name, value)(value as string);
    };

  const handleBlur =
    (name: string) =>
    (event: ChangeEvent<HTMLInputElement> | Date | string) => {
      try {
        validateOrSetErrors(fields[name], name);
      } catch (e) {
        console.error(e);
      }
    };

  const validateOrSetErrors = (field: IFormField, name: string) => {
    validateField(field);
    if (field.watch && field.watch.length) {
      for (const input of field.watch) {
        validateField(fields[input]);
      }
    }
    const isFormValid = Object.keys(fields).every(
      (field) => fields[field].valid,
    );
    setFields({
      ...fields,
      [name]: {
        ...field,
        value: field.value,
      },
    });
    setValid(isFormValid);
    return isFormValid;
  };

  const getValidation = () => {
    const validFields = Object.keys(fields).map((name) => {
      const input = fields[name];
      const isValid = validateOrSetErrors(input, name);
      return isValid;
    });
    return validFields.includes(false);
  };

  const handleSubmit = () => {
    getValidation();
    if (!getValidation() && !props.noValidation) {
      if (props.onSubmit) {
        const parsedFormWithValues: any = {};
        Object.keys(fields).forEach(
          (name) => (parsedFormWithValues[name] = fields[name].value),
        );
        props.onSubmit(parsedFormWithValues);
      }
    }
  };

  const highlightRequired = () => {
    Object.keys(fields).forEach((name) => {
      const input = fields[name];
      validateOrSetErrors(input, name);
    });
  };

  const validateField = (field: IFormField) => {
    if (field.validations) {
      const validations = field.validations;
      const errors = [];
      if (validations.required) {
        if (
          typeof field.value === "object" &&
          field.value.length !== 0 &&
          typeof field.value[0] === "object" &&
          !Array.isArray(field.value)
        ) {
          if (!requiredSelected(field.value)) {
            errors.push(validations.required.errorMessage);
          }
        } else if (field.value instanceof Date) {
          if (!requiredDate(field.value)) {
            errors.push(validations.required.errorMessage);
          }
        } else if (Array.isArray(field.value)) {
          if (!requiredMultiselect(field.value)) {
            errors.push(validations.required.errorMessage);
          }
        } else {
          if (!required(field.value)) {
            errors.push(validations.required.errorMessage);
          }
        }
      }

      if (validations.regex) {
        if (!validations.regex.test.test(field.value)) {
          errors.push(validations.regex.errorMessage);
        }
      }

      if (validations.dateFormat) {
        if (!requiredDate(field.value)) {
          errors.push(validations.dateFormat.errorMessage);
        }
      }
      if (validations.emailFormat) {
        if (!email(field.value)) {
          errors.push(validations.emailFormat.errorMessage);
        }
      }
      if (validations.curp) {
        if (!curp(field.value)) {
          errors.push(validations.curp.errorMessage);
        }
      }
      if (validations.rfc) {
        if (!rfc(field.value)) {
          errors.push(validations.rfc.errorMessage);
        }
      }
      if (validations.nss) {
        if (!nss(field.value)) {
          errors.push(validations.nss.errorMessage);
        }
      }
      if (validations.positiveNumber) {
        if (!positiveNumber(field.value)) {
          errors.push(validations.positiveNumber.errorMessage);
        }
      }
      if (validations.clabe) {
        if (!clabe(field.value)) {
          errors.push(validations.clabe.errorMessage);
        }
      }
      if (validations.minLength) {
        if (!minLength(validations.minLength.min)(field.value)) {
          errors.push(validations.minLength.errorMessage);
        }
      }
      if (validations.maxLength) {
        if (!maxLength(validations.maxLength.max)(field.value)) {
          errors.push(validations.maxLength.errorMessage);
        }
      }
      if (validations.minValue) {
        if (!minValue(validations.minValue.min)(field.value)) {
          errors.push(validations.minValue.errorMessage);
        }
      }
      if (validations.maxValue) {
        if (!maxValue(validations.maxValue.max)(field.value)) {
          errors.push(validations.maxValue.errorMessage);
        }
      }
      if (validations.match) {
        const matchingField = fields[validations.match.match];
        if (matchingField) {
          if (!match(matchingField.value)(field.value)) {
            errors.push(validations.match.errorMessage);
          }
        }
      }
      if (validations.string) {
        if (!string(field.value)) {
          errors.push(validations.string.errorMessage);
        }
      }
      if (validations.length) {
        if (!length(validations.length.length)(field.value)) {
          errors.push(validations.length.errorMessage);
        }
      }
      if (validations.numeric) {
        if (!numeric(field.value)) {
          errors.push(validations.numeric.errorMessage);
        }
      }
      if (validations.float) {
        if (!float(field.value)) {
          errors.push(validations.float.errorMessage);
        }
      }
      if (validations.boolean) {
        if (typeof field.value !== "boolean") {
          errors.push(validations.boolean.errorMessage);
        }
      }

      if (validations.required === undefined && field.value === "") {
        // Non required empty fields
        field.errors = [];
        field.valid = true;
      } else {
        let validate = true;
        if (validations.requiredIf) {
          const input = fields[validations.requiredIf.field];
          if (input) {
            validate = input.value === validations.requiredIf.equals;
          }
        }
        if (validate) {
          // Modify field
          field.errors = errors.length > 0 ? [errors[0]] : []; //errors; //If an input has more than 1 error they stack below
          //and overlaps other elements there
          field.valid = errors.length > 0 ? false : true;
        } else {
          // Non required empty fields bc of condition
          field.errors = [];
          field.valid = true;
        }
      }
    }
  };

  // First render doesn't have fields, so this is necesary
  if (Object.keys(fields).length === 0) {
    return <></>;
  }
  return props.children({
    fields,
    handleChange,
    handleChangeValue,
    handleSubmit,
    highlightRequired,
    handleBlur,
    handleClear,
    valid,
  }) as ReactElement<any>;
};
export default Form;
