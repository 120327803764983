import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IDistrict } from "../../../models/District";
import { ISchool } from "../../../models/School";
import { openModal, PayloadOpenModal } from "../../../redux/actions/modal";
import { fetchDistricts } from "../../../services/district";
import { fetchSchoolsPaginated } from "../../../services/schools";

interface ISchoolData {
  lodingSchools: boolean;
  schools: ISchool[];
}

interface IDistrictsData {
  lodingDistricts: boolean;
  districts: IDistrict[];
}

const initialSchoolData: ISchoolData = {
  lodingSchools: false,
  schools: [],
};

const initialDistrictsData: IDistrictsData = {
  lodingDistricts: false,
  districts: [],
};

export type ICatalogs = ISchoolData & IDistrictsData;

/**
 * useCatalogs Hook: Get the data for the dropdowns of schools and districts
 * @returns {ICatalogs} catalogs
 */
export function useCatalogs() {
  // --- CONST / HOOKS
  const [schoolData, setSchoolData] = useState<ISchoolData>(initialSchoolData);
  const [districtsData, setDistrictsData] = useState<IDistrictsData>(initialDistrictsData); 
  const dispatch=useDispatch()
  useEffect(() => {
    getOptions();
  }, []);

  function getOptions() {
    setSchoolData({ ...schoolData, lodingSchools: true});
    setDistrictsData({ ...districtsData, lodingDistricts: true });
    fetchSchoolsPaginated()
      .then((response) => {
        const { data: schools } = response;
        setSchoolData({ ...schoolData, schools, lodingSchools: false });
      })
      .catch((err) => {
        handleErrorModal();
        setSchoolData({ ...schoolData, lodingSchools: false });
      });
    fetchDistricts()
      .then((response) => {
        const { data: districts } = response;
        setDistrictsData({ ...districtsData, districts, lodingDistricts: false });
      })
      .catch((err) => {
        handleErrorModal();
        setDistrictsData({ ...districtsData, lodingDistricts: false });
      });
  }

  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Error creating User`,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  const catalogs: ICatalogs = {
    ...schoolData,
    ...districtsData,
  }
  return catalogs
}