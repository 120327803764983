import { Box } from "@mui/material";
import React from "react";

const styles = {
  layout: {
    display: "flex",
    flex: 1,
    justifyContent:"center",
    alignContent:"center",
    padding: {
      sm: "20px 48px",
      xs: "20px 20px",
    },
    marginTop: "8.5rem",
  },
} as const;

const MainLayout: React.FC = ({ children }) => (
  <Box sx={styles.layout}>{children}</Box>
);

export default MainLayout;
