// ---Dependencies
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDrillCount } from "../../../services/eventInProgress";
import { openModal, PayloadOpenModal } from "../../../redux/actions/modal";
import { ItemGrid } from "../../DashboardSafetyCommander/components/CustomCard";
import { useDispatch } from "../../../redux/typedHooks";
import { ISchoolInterface } from "./interfaces";
import { displayToast } from "../../../utils/helpers/displayToast";

export const DrillCount: React.FC<ISchoolInterface> = ({ schoolId }) => {
  const [drillCount, setDrillCount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    getDrillCount()
      .then((response) => {
        
        setDrillCount(response.data.payload.count);
      })
      .catch((err) => {
        displayToast("error", err);
      });
  }, []);
  /*  function handleErrorModal(message: any) {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: message,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  } */
  return (
    <ItemGrid>
      <Box height="100%">
        <Typography variant="h6" align="center" gutterBottom>
          Total Number of Drills
        </Typography>
        <Typography
          style={{
            fontSize: "32px",
            width: "100%",
            
            marginTop: "40px",
          }}
          variant="subtitle2"
          align="center"
        >
          {drillCount}
        </Typography>
      </Box>
    </ItemGrid>
  );
};
