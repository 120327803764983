import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useState } from "react";

import {
  roles,
  rolesNames,
  schoolRoles,
  schoolRolesNames,
} from "../../../constants";
import { IUserFormProps } from "../../../models/Users";
import { ICatalogs } from "../../../pages/CreateUserSuperadmin/config/useCatalogs";
import { useSelector } from "../../../redux/typedHooks";
import { ConditionalForm } from "./ConditionalForm/ConditionalForm";
import { SimpleTextF } from "./SimpleTextF/SimpleTextF";

interface Props extends IUserFormProps {
  catalogs: ICatalogs;
}
const UserFormSuperA: React.FC<Props> = ({ formik, catalogs }) => {
  // -----------------------CONSTS, HOOKS, STATES
  const [localRole, setRole] = useState<string | null | undefined>(
    formik.values.role,
  );
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const isDistrictAdmin = user?.role?.name === "DISTRICT_ADMIN";
  // -----------------------MAIN METHODS
  function onChangeAutoComp(_: any, value: string | null) {
    const selectedRoleCode = Object.keys(rolesNames).filter(
      (key) => rolesNames[key] === value,
    )[0];
    const oldRoleName = Object.keys(roles).filter(
      (key) => roles[key] === selectedRoleCode,
    )[0];
    setRole(oldRoleName);
    formik.setFieldValue("role", oldRoleName);
    formik.setFieldValue("districts", []);
    formik.setFieldValue("school", undefined);
  }
  return (
    <Box
      sx={{
        maxWidth: "500px",
        marginTop: 5,
        "& .MuiTextField-root": { m: 1, width: "100%" },
        flex: 1,
      }}
    >
      <SimpleTextF label="Email*" formik={formik} valueName="email" />
      <SimpleTextF label="First name*" formik={formik} valueName="firstName" />
      <SimpleTextF label="Last name*" formik={formik} valueName="lastName" />
      <SimpleTextF
        label="Phone number*"
        formik={formik}
        valueName="phoneNumber"
      />
      <Autocomplete
        value={formik.values.role}
        onChange={onChangeAutoComp}
        options={Object.keys(
          isDistrictAdmin ? schoolRolesNames : rolesNames,
        ).map((key: string) =>
          isDistrictAdmin ? schoolRolesNames[key] : rolesNames[key],
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Role*"
            variant="outlined"
            error={!!formik.errors.role && !!formik.touched.role}
            helperText={
              !!formik.errors.role &&
              !!formik.touched.role &&
              formik.errors.role
            }
          />
        )}
      />
      <ConditionalForm
        catalogs={catalogs}
        localRole={localRole}
        formik={formik}
      />
    </Box>
  );
};

export default UserFormSuperA;
