import React from "react";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import LocationForm from "../../components/LocationForm/LocationFom";
import MainLayout from "../../components/MainLayout";

const LocationUpdate: React.FC = () => {
  const { id } = useParams() as any;
  return (
    <div>
      <PageTitle title="Update Location" enableGoBack />
      <MainLayout>
        <LocationForm action="update" locationId={id} />
      </MainLayout>
    </div>
  );
};

export default LocationUpdate;
