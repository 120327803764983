import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import { CustomSort } from "../../../components/DataGrid";
import { roles, rolesNames } from "../../../constants";

export default function districtColumns(
  columnAction: GridColDef,
): GridColDef[] {
  return [
    {
      headerName: "Name",
      field: "fullName",
      hideSortIcons: true,
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      valueGetter: (param: any) => {
        return rolesNames[roles[param.row?.role?.name]];
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef?.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Email Adress",
      field: "email",
      flex: 1,
      hideSortIcons: true,
      minWidth: 230,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Status",
      field: "disabled",
      flex: 1,
      hideSortIcons: true,
      minWidth: 90,
      valueGetter: (param: any) => {
        return param.row.disabled ? "INACTIVE" : "ACTIVE";
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    columnAction,
  ];
}
