import { makeStyles } from "@mui/styles";
import { paletteColors, theme } from "../../styles/theme";

export const useStyles = makeStyles(() => ({
    mainWrapper: {
      display: "flex",
      flex: 1,
      /* padding: "20px 16px", */
      maxWidth: "100%",
      alignSelf: "center",
    },
    formContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      overflow: "auto",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: "#000",
      backgroundColor: "#FFF",
      margin: "0px",
      minWidth: "250px",
      maxWidth: "60vw",
      boxShadow: "0px 8px 15px 1px rgba(0,0,0,0.10)",
    },
    form: {
      padding: "1.5rem 16px 1rem 16px",
    },
    rowContainer: {
      display: "flex",
      minHeight: theme.spacing(9),
    },
    rowContainerNormal: {
      display: "flex",
      flex: 1,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "30vh",
      [theme.breakpoints.down("xl")]: {
        marginTop: "19vh",
      },
      alignItems: "center",
    },
    label: {
      marginBottom: -30,
    },
    loader: {
      position: "absolute",
      marginLeft: "auto",
      marginRight: "auto",
    },
    bodyToast: {
      whiteSpace: "pre-line",
    },
    divider: {
      margin: "40px 0 20px 0",
    },
    cancelButton: {
      marginRight: 20,
      borderWidth: 0,
      "&:hover": {
        borderWidth: 0,
      },
    },
    typeContainer: {
      display: "flex",
      flexBasis: "28%",
      paddingRight: "16px",
      [theme.breakpoints.up("xl")]: {
        paddingRight: "50px",
      },
    },
    datePickerContainer: {
      display: "flex",
      flexBasis: "28%",
    },
    bankContainer: {
      display: "flex",
      flexBasis: "23%",
      paddingRight: "16px",
      [theme.breakpoints.up("xl")]: {
        flexBasis: "17%",
        paddingRight: "0px",
      },
    },
    clabeContainer: {
      display: "flex",
      flexBasis: "23%",
      [theme.breakpoints.up("xl")]: {
        flexBasis: "23%",
      },
    },
    phoneContainer: {
      display: "flex",
      flexBasis: "28%",
      paddingRight: "16px",
    },
    infonavitContainer: {
      display: "flex",
      flexBasis: "28%",
      paddingRight: "16px",
    },
    stationContainer: {
      display: "flex",
      flexBasis: "20%",
      paddingRight: "16px",
    },
    employeeContainer: {
      display: "flex",
      flexBasis: "20%",
    },
    positionContainer: {
      display: "flex",
      flexBasis: "20%",
    },
    supplierContainer: {
      display: "flex",
      flexBasis: "30%",
    },
    editSaveButtons: {
      width: "90px",
    },
    checkInput: {
      color: `${theme.palette.primary.main} !important`,
    },
    checkInputRoot: {
      padding: "17px 0px 0px 5px",
      width: "100%",
    },
    checkInputLabel: {
      fontSize: "16px !important",
    },
    gridFormAdjuster: {
      marginLeft: "-8px",
      marginRight: "-8px",
    },
    bigItem: {
      width: "100%",
      marginBottom: "40px",
      maxHeight: "52px",
      [theme.breakpoints.up("md")]: {
        width: "50%",
        marginBottom: "42px",
      },
    },
    smallItem: {
      width: "100%",
      marginBottom: "40px",
      maxHeight: "52px",
      [theme.breakpoints.up("md")]: {
        width: "50%",
        marginBottom: "42px",
      },
    },
    smallItemRightAdjuster: {
      [theme.breakpoints.up("xl")]: {
        marginRight: "30%",
      },
    },
    formGrid: {
      marginBottom: "16px",
      marginInline: "0px",
    },
    customInput30: {
      [theme.breakpoints.up("xl")]: {
        width: "30%",
      },
    },
    customInput20: {
      [theme.breakpoints.up("xl")]: {
        width: "20%",
      },
    },
    customInput25: {
      [theme.breakpoints.up("xl")]: {
        width: "25%",
      },
    },
    customInput17: {
      [theme.breakpoints.up("xl")]: {
        width: "17%",
      },
    },
    customInput23: {
      [theme.breakpoints.up("xl")]: {
        width: "23%",
      },
    },
    customPaddingRight: {
      [theme.breakpoints.up("xl")]: {
        paddingRight: "70px",
      },
    },
    customInputDatePicker: {
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        width: "75%",
      },
    },
    maxWithXSmall: {
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        width: "168px",
      },
    },
    maxWithSmall: {
      [theme.breakpoints.up("xl")]: {
        maxWidth: "208px",
      },
    },
    maxWithBig: {
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        width: "270px",
      },
    },
    customPaddingTop: {
      marginTop: "-30px",
    },
    commentsInput: {
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        width: 430,
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: "100%",
        maxWidth: 430,
      },
    },
  }));