import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

export const ItemGrid = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(2),
  textAlign: "center",
  height: "100%",
  width: "100%",
  color: "#4d4d4d",
}));
