import {
  Badge,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

//--Components
import CommetChat from "../../../components/CommetChat";
import PageTitle from "../../../components/DashboardLayout/PageTitle";
import HeadCountForm from "../../../components/HeadCountForm";
import { IHeadCountProps } from "../../../components/HeadCountForm/interfaces";
import { headCountSchema } from "../../../components/HeadCountForm/schemas";
import PanicButton from "../../../components/PanicButton/PanicButton";
import { useSocket } from "../../../hooks/useSocket";
import { useSelector } from "../../../redux/typedHooks";
import { getLocationEvent } from "../../../services/eventInProgress";
import { displayToast } from "../../../utils/helpers/displayToast";
import { TabPanel } from "../components/Tab";

export interface ICreateOrUpdateEventLocation {
  runningEvent: string;
  school: string;
  locationId: string;
  status: string;
  assignedTeacher: string;
  headCount: number;
  notes?: string;
  additionalStudents: string[];
  missingStudents: string[];
  missingCount: number;
  additionalCount: number;
}

const WithEvent: React.FC<{ messages: number }> = ({ messages }) => {
  // TODO: do a little refactor to this 2 states
  const [onInitialValues, triggerInitialValues] = useState(0);
  const [resetForm, setResetForm] = useState(0);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { socket } = useSocket();
  const formik = useFormik<IHeadCountProps>({
    initialValues: {
      school: "",
      locationId: "",
      assignedTeacher: "",
      missingCount: "",
      additionalCount: "",
      headCount: "",
      status: "",
      additionalStudents: [],
      missingStudents: [],
      notes: "",
    },
    validationSchema: headCountSchema,
    onSubmit,
  });
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const data = {
      school: user.school._id,
      eventId: user.school.runningEvent,
      assignedTeacher: user._id,
    };
    getLocationEvent(data)
      .then(({ data }) => {
        formik.setFieldValue("locationId", data.location);
        formik.setFieldValue("missingCount", data.missingCount);
        formik.setFieldValue("additionalCount", data.additionalCount);
        formik.setFieldValue("headCount", data.headCount);
        formik.setFieldValue("status", data.status);
        formik.setFieldValue("additionalStudents", data.additionalStudents);
        formik.setFieldValue("missingStudents", data.missingStudents);
        formik.setFieldValue("notes", data.notes);
        triggerInitialValues(onInitialValues + 1);
      })
      .catch((err) => console.log(err));
  }, [user.school.runningEvent]);

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  function onSubmit(values: IHeadCountProps) {
    const data = {
      ...values,
      runningEvent: user.school.runningEvent,
    } as unknown as ICreateOrUpdateEventLocation;
    socket?.emit("update-location", data);
    triggerInitialValues(onInitialValues + 1);
    displayToast("success", "Data has updated successfully");
  }

  return (
    <>
      <PageTitle title="Head Count Form" />
      <Box sx={{ height: `calc(100vh - ${64}px)`, marginTop: "70px" }}>
        <Toolbar />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <PanicButton
              name="addAlert"
              iconPosition="start"
              label="Alert"
              variant="contained"
              color="error"
            />
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={<Typography sx={{ padding: 1 }}>Form</Typography>} />

              <Tab
                label={
                  <Badge badgeContent={messages} color="primary">
                    <Typography sx={{ padding: 1 }}>Chat</Typography>
                  </Badge>
                }
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Paper sx={{ padding: 2, width: "100%" }}>
              <HeadCountForm
                formik={formik}
                hasInitialValues={onInitialValues}
                resetForm={resetForm}
              />
              <Grid
                sx={{ marginY: "12px" }}
                container
                spacing={3}
                justifyContent="flex-end"
              >
                <Button
                  onClick={() => setResetForm(resetForm + 1)}
                  variant="outlined"
                >
                  RESET
                </Button>
                <Button
                  sx={{ marginLeft: "8px" }}
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                >
                  SAVE
                </Button>
              </Grid>
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CommetChat />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default WithEvent;
