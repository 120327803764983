//Dependencies
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
// ---UI Dependencies
import { styled } from "@mui/styles";
import { Card, Button, Grid } from "@mui/material";
// ---Redux
import { useDispatch } from "../../redux/typedHooks";
import { PayloadOpenModal, openModal } from "../../redux/actions/modal";
// ---Components
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import DistrictForm from "../../components/DistrictForm";
// ---Config
import { useFormikConfig } from "./config/useFormikConfig";
// ---Services/Requests
import { fetchDistrictById, updateDistrict } from "../../services/district";

// -----------------------Interfaces
import { IDistrictForm } from "../../components/DistrictForm/IDistrictFormProps";
import { useLoading } from "../../hooks/useLoading";

// -----------------------STYLES
const LayoutContainer = styled(Card)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
}));

const UpdateDistrict = () => {
  // -----------------------CONSTS, HOOKS, STATES
  const { handleClose, handleOpen } = useLoading();
  const history = useHistory();
  const formik = useFormikConfig(onSubmit);
  const id = (useParams() as any).id;
  const dispatch = useDispatch();

  useEffect(() => {
    initialConfig(id);
  }, [id]);

  // -----------------------MAIN METHODS
  function initialConfig(id: string) {
    fetchDistrictById(id)
      .then(({ data }) => {
        formik.setFieldValue("number", data?.number);
        formik.setFieldValue("name", data.name);
        formik.setFieldValue("state", data.state);
        formik.setFieldValue("zipCode", data.zipCode);
        formik.setFieldValue("pointOfContact", data?.pointOfContact);
      })
      .catch(() => {
        history.replace("/districts");
      });
  }

  async function onSubmit(values: IDistrictForm, { setFieldError }: any) {
    try {
      handleOpen();
      await updateDistrict(values, id);
      handleSuccessModal();
    } catch {
      handleErrorModal();
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Success",
      description: `District updated successfully`,
      action: () => history.goBack(),
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Error updating District`,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }
  // ------------------------- RENDER
  return (
    <>
      <PageTitle title="Edit District" />
      <MainLayout>
        <LayoutContainer>
          <DistrictForm formik={formik as any} />
          <Grid
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            container
            spacing={3}
            justifyContent="flex-end"
          >
            <Button onClick={() => history.goBack()} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              sx={{ marginLeft: "10px" }}
              variant="contained"
            >
              SAVE
            </Button>
          </Grid>
        </LayoutContainer>
      </MainLayout>
    </>
  );
};

export default UpdateDistrict;
