// ---Dependencies
import { useEffect } from "react";
// ---UI DEPENDENCIES
// ---Redux
// ---Components
import { useMediaQuery } from "@mui/material";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import { useSocket } from "../../hooks/useSocket";
import { changeValueEventProgress } from "../../redux/actions/LoggedUser";
import {
  changeStatusTriggerEvent,
  editHeadcount,
  finishEvent,
  setDataEventInProgress,
  stopEvent,
} from "../../redux/actions/triggerEvent";
import { IDataEvent } from "../../redux/reducers/triggerEvent";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { getCurrentEvent } from "../../services/eventInProgress";
import { displayToast } from "../../utils/helpers/displayToast";
import { getRoleName } from "../routes/role-catalog";
import Desktop from "./layouts/Desktop";
import Mobile from "./layouts/Mobile";
import TriggerEvent from "./layouts/TriggerEvent";

const DashboardSafetyCommander = () => {
  const dispatch = useDispatch();
  const started = useSelector((store) => store.triggerEvent.started);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const expectedHeadcount = useSelector((store) => store.triggerEvent.data);
  const { socket } = useSocket();
  const isMobile = useMediaQuery("(max-width:860px)");
  const existEvent = user.school.runningEvent;
  const responsiveComponent = isMobile ? (
    <Mobile handleStop={handleStopEvent} />
  ) : (
    <Desktop handleStop={handleStopEvent} />
  );

  
  useEffect(() => {
    socket?.on("update-location", (data: IDataEvent) => {
      dispatch(setDataEventInProgress(data));
    });
  }, [user.school._id]);

  useEffect(() => {
    socket?.on("finish-event", (data) => {
      displayToast("success", "Event has finished");
      dispatch(finishEvent());
    });
  }, []);

  useEffect(() => {
    socket?.on("start-event", ({ eventId }) => {
      dispatch(changeValueEventProgress(eventId));
      displayToast("success", "Event has started");
    });
  }, []);


   useEffect(() => {
    socket?.on("update-headcount", (data) => {
      const { role } = data;
      dispatch(editHeadcount())
      displayToast("success", `The ${getRoleName(role)} has updated the headcount`, "uniqueId");
    });

  }, [expectedHeadcount]);
    
  useEffect(() => {
     getCurrentEvent(user.school._id)
      .then(({ data }) => {
        dispatch(setDataEventInProgress(data.payload));
      })
      .catch(() => {
        dispatch(changeStatusTriggerEvent(false));
      });
  }, [user.school._id]);
  useEffect(() => {
    getCurrentEvent(user.school._id)
      .then(({ data }) => {
        dispatch(setDataEventInProgress(data.payload));
      })
      .catch(() => {
        dispatch(changeStatusTriggerEvent(false));
      });
  }, [started]);


  function handleStopEvent(headCount: number | undefined) {
    return () => {
      socket?.emit("finish-event", user.school._id);
      dispatch(stopEvent({ school: user.school._id, headcount: headCount }));
    };
  }

  return (
    <>
      <PageTitle title="Dashboard" />
      {started || existEvent ? responsiveComponent : <TriggerEvent />}
    </>
  );
};

export default DashboardSafetyCommander;
