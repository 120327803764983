// ---Dependencies
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { openModal, PayloadOpenModal } from "../../../redux/actions/modal";
import { useDispatch } from "../../../redux/typedHooks";
import { getEmergencyCount } from "../../../services/eventInProgress";
import { displayToast } from "../../../utils/helpers/displayToast";
import { ItemGrid } from "../../DashboardSafetyCommander/components/CustomCard";
import { ISchoolInterface } from "./interfaces";

export const EmergencyCount: React.FC<ISchoolInterface> = ({ schoolId }) => {
  const [emergencyCount, setEmergencyCount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    getEmergencyCount()
      .then((response) => {
        setEmergencyCount(response.data.payload.count);
      })
      .catch((err) => {
        displayToast("error", err);
      });
  }, []);
  /*   function handleErrorModal(message: any) {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: message,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  } */
  return (
    <ItemGrid>
      <Box height="100%">
        <Typography variant="h6" align="center" gutterBottom>
          Total Number of Emergencies
        </Typography>
        <Typography
          style={{
            fontSize: "32px",
            width: "100%",
            textAlign: "center",
            marginTop: "40px",
          }}
          variant="subtitle2"
          align="left"
        >
          {emergencyCount}
        </Typography>
      </Box>
    </ItemGrid>
  );
};
