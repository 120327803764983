import { GetApp } from "@mui/icons-material";
import { Box, createTheme, SelectChangeEvent } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import React from "react";

import { ISelectOption } from "../Inputs/Select";

import CustomPagination from "./CustomPagination";
import CustomToolbar from "./CustomToolbar";
import { DEFAULT_ROWS_PER_PAGE } from "./constants";
import styles from "./styles";

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: "white",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#E2E2E280",
        },
        "& .MuiDataGrid-row": {
          backgroundColor: "",
        },
      },
    }),
  { defaultTheme },
);

interface IToolbarProps {
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch?: () => void;
  filterOptions?: ISelectOption[];
  onFilterChange?: (event: SelectChangeEvent<unknown>) => void;
  buttonText?: string;
  buttonAction?: () => void;
  hideFilters?: boolean;
  filterLabel?: string;
  filterField?: string;
  fileName?: string;
  hideIcons?: boolean;
}

interface ITableProps extends DataGridProps {
  toolbarProps?: IToolbarProps;
}
/**
 * Componente que sirve para mostrar y filtrar datos sobre una tabla interactiva. También se le puede configurar la tabla de herramientas incluida
 * @param {GridColumns} columns Arreglo que indica el tipo de columnas a pintar
 * @param {Array} rows Los datos a insertar en la tabla
 * @param {number[]} rowsPerPageOptions La cantidad de filas por página mostradas
 * @param {IToolbarProps} toolbarProps Las opciones relacionadas a la barra de herramientas
 */

const CustomDataGrid: React.FC<ITableProps> = (props) => {
  const { columns, rows, rowsPerPageOptions, toolbarProps } = props;
  const classes = useStyles2();
  const [pageSize, setPageSize] = React.useState<number>(5);

  return (
    <Box sx={styles.DataGridContainer}>
      <DataGrid
        className={classes.root}
        checkboxSelection
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE}
        pagination
        sortingOrder={["asc", "desc"]}
        localeText={{
          toolbarExportLabel: "",
          toolbarExport: "",
          toolbarDensity: "",
          toolbarDensityLabel: "",
        }}
        components={{
          Toolbar: CustomToolbar,
          ExportIcon: GetApp,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: toolbarProps,
          pagination: {
            rowsPerPage: rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE,
          },
        }}
        {...props}
        rows={rows}
        columns={columns}
      />
    </Box>
  );
};
export default CustomDataGrid;
