import { roles, rolesNames } from "../../../constants";

export const rolesFilter: {
  name: string;
  value: string;
}[] = [
  {
    name: 'All Roles',
    value: 'All Roles',
  },
  ...Object.keys(
    rolesNames,
  ).map((key: string) => ({
    name: rolesNames[key],
    value: rolesNames[key],
  })),
]