import { roles } from "../../../constants";
import { IUserForm, IUserRequest } from "../../../models/Users";

export function fitDataCreateUser(formData: IUserForm): IUserRequest {
  const noDistricts = formData?.districts?.length === 0;
  const districts = noDistricts?null:formData?.districts.map(element=>element._id)
  const fixedData: IUserRequest = {
    ...formData,
    role: roles[(formData as { role: "SAFETY_COMMANDER" })?.role],
    districts,
  }
  return fixedData
}