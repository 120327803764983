import { AxiosResponse, AxiosRequestConfig } from 'axios';

import axios from '../config';

const resetPasswordRequest = (email: string): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: '/reset-password',
    method: 'POST',
    data: { email },
  };

  return axios(config);
};

const resetPassword = (
  token: string,
  password: string,
  userId: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: '/reset-password',
    method: 'PUT',
    data: { token, password, userId },
  };

  return axios(config);
};

export { resetPasswordRequest, resetPassword };
