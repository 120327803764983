// ---Dependencies
import { Box, CircularProgress } from "@mui/material";
import { ReactElement } from "react";
// ---Types
import { IUserFormProps } from "../../../../models/Users";
import { ICatalogs } from "../../../../pages/CreateUserSuperadmin/config/useCatalogs";
// ---Components
import { DistrictsInput } from "./DistrictsInput/DistrictsInput";
import { SchoolsInput } from "./SchoolsInput/SchoolsInput";
// ---UI Dependencies
// ---Custom Hooks
// ---Redux
// ---Config
// ---Assets
// ---Utils
// ---Constants
// ---Services/Requests
// ---Images

interface Props extends IUserFormProps {
  localRole?: string | null;
  catalogs: ICatalogs;
}
/**
 * ConditionalForm Component: Do something
 * @returns {ReactElement}
 */
export function ConditionalForm({
  formik,
  localRole,
  catalogs,
}: Props): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const isSchoolUser = ["TEACHER", "SAFETY_TEAM", "SAFETY_COMMANDER","ENROLLMENT_LEADER"].includes(String(localRole));
  const isDistrictAdmin = "DISTRICT_ADMIN" === localRole;
  // -----------------------MAIN METHODS

  // -----------------------AUX METHODS
  // -----------------------RENDER
  if (isSchoolUser) {
    return catalogs?.lodingSchools ? (
      <LoadingSpinner />
    ) : (
      <SchoolsInput formik={formik} schools={catalogs.schools} />
    );
  }
  if (isDistrictAdmin) {
    return catalogs?.lodingDistricts ? (
      <LoadingSpinner />
    ) : (
      <DistrictsInput formik={formik} districts={catalogs.districts} />
    );
  }
  return <div />;
}

function LoadingSpinner() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
}
