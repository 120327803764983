// ---Dependencies
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
//Components
import PageTitle from "../../../components/DashboardLayout/PageTitle";
import PanicButton from "../../../components/PanicButton/PanicButton";
import { openModal, PayloadOpenModal } from "../../../redux/actions/modal";

// ---Redux
import { useDispatch, useSelector } from "../../../redux/typedHooks";
import { fetchLocations } from "../../../services/locations";
import { displayToast } from "../../../utils/helpers/displayToast";
import { ERoles } from "../../routes/role-catalog";
import { TriggerEventModal } from "../../TriggerEvent/TriggerEventModal/TriggerEventModal";

const TriggerEvent = () => {
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const [disabled, setDisabled] = useState(true);
  const [showTriggerModal, setTriggerModal] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setTriggerModal(true);
  };
  const handleCloseModals = () => {
    setTriggerModal(false);
  };

  async function isThereLocations() {
    try {
      const response = await fetchLocations();
      const filteredLocations = response.data.payload.fixedLocations.filter(
        (location: any) => {
          return location.school === user.school._id;
        },
      );
      if (filteredLocations.length === 0) {
        const modalConfig: PayloadOpenModal = {
          title: "No Locations Found",
          description: "Cannot trigger event without creating locations first",
        };
        dispatch(openModal(modalConfig));
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } catch (error) {
      displayToast("error", "Error obtaining locations");
    }
  }
  useEffect(() => {
    isThereLocations();
  }, []);
  return (
    <>
      <PageTitle title="Trigger event" enableGoBack />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 150,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: { xs: "90%", sm: "80%", md: "20%", lg: "15%", xl: "10%" } }}
          disabled={disabled || user.role.code === ERoles.RL005 }
          >
          Trigger Event
        </Button>
        <PanicButton
          name="addAlert"
          iconPosition="start"
          label="Alert"
          variant="contained"
          color="error"
          sx={{
            width: { xs: "90%", sm: "80%", md: "20%", lg: "15%", xl: "10%" },
          }}
          style={{ marginTop: 10 }}
          />
        {showTriggerModal && (
          <TriggerEventModal
            open={showTriggerModal}
            handleClose={handleCloseModals}
            school={user?.school._id}
          />
        )}
      </div>
    </>
  );
};

export default TriggerEvent;
