import { useEffect } from "react";
import useMessageListener from "../../hooks/useMessageListener";
import { useSocket } from "../../hooks/useSocket";
import { changeValueEventProgress } from "../../redux/actions/LoggedUser";
import { editHeadcount, finishEvent } from "../../redux/actions/triggerEvent";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { displayToast } from "../../utils/helpers/displayToast";
import WithEvent from "./layout/WithEvent";
import WithOutEvent from "./layout/WithOutEvent";

const EventInProgress = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { socket } = useSocket();
  const existEvent = user.school.runningEvent;

  useEffect(() => {
    socket?.on("finish-event", (data) => {
      displayToast("success", "Event has finished");
      dispatch(finishEvent());
    });
  }, []);

  useEffect(() => {
    socket?.on("start-event", ({ eventId }) => {
      dispatch(changeValueEventProgress(eventId));
      displayToast("success", "Event has started");
    });
  }, []);

   useEffect(() => {
    socket?.on("update-headcount", () => {
      dispatch(editHeadcount())
      displayToast("success", "Head count has been successfully updated");
    });
  }, []);

  const messages = useMessageListener();
  
  return existEvent ? <WithEvent messages={messages} /> : <WithOutEvent />;
};

export default EventInProgress;
