import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "../../../redux/typedHooks";
import CustomCometChat from "../../../components/CustomCometChat";
import PanicButton from "../../../components/PanicButton/PanicButton";
import DataGridAdditionalPeople from "../components/DataGridAdditionalPeople";
import DataGridLocationInfo from "../components/DataGridLocationInfo";
import DataGridMissingPeople from "../components/DataGridMissingPeople";
import EventGeneralInformation from "../components/EventGeneralInformation";
import HeadCountInfo from "../components/HeadCountInfo";
import MissingCount from "../components/MissingCount";
import StopEventDialog from "../components/StopEventDialog";
import TimeRunning from "../components/TimeRunning";
import { styles } from "../styles";
import { ERoles } from "../../routes/role-catalog";

const Desktop: React.FC<{
  handleStop: (arg0: number | undefined) => () => void;
}> = ({ handleStop }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { triggerEvent } = useSelector((store) => store);
  const  { loggedUser }  = useSelector((store) => store);

  const [isEnrolmentLeader, setIsEnrolmentLeader] = useState(false)
  useEffect(() => {
    setIsEnrolmentLeader(loggedUser.loggedUser.user.role.code === ERoles.RL005)
  }, [])
  

  const handleCloseModal = () => setShowConfirmation(false);
  return (
    <Box sx={styles.gridLayout}>
      <Box sx={styles.buttonStopEventContainer}>
        <PanicButton
          name="addAlert"
          iconPosition="start"
          label="Alert"
          variant="contained"
          color="error"
        />
        <Button
          onClick={() => setShowConfirmation(true)}
          variant="contained"
          sx={{ backgroundColor: "red" }}
          disabled={isEnrolmentLeader}
        >
          STOP EVENT
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={7}>
            <EventGeneralInformation />
          </Grid>
          <Grid item xs={2.5}>
            <TimeRunning />
          </Grid>
          <Grid item xs={2.5}>
            <MissingCount />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12} sx={{ height: "100%" }}>
          <Grid container item spacing={2} xs={7}>
            <Grid item xs={12}>
              <HeadCountInfo />
            </Grid>
            <Grid item xs={12}>
              <DataGridLocationInfo />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <DataGridMissingPeople />
              </Grid>
              <Grid item xs={6}>
                <DataGridAdditionalPeople />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <CustomCometChat />
          </Grid>
        </Grid>
      </Grid>
      {showConfirmation && (
        <StopEventDialog
          open={true}
          title={`Would you like to FINISH the current event?`}
          cancelButtonAction={handleCloseModal}
          confirmButtonAction={handleStop}
          originalHeadCount={triggerEvent.data.expectedHeadCount}
        />
      )}
    </Box>
  );
};

export default Desktop;
