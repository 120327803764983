import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Logo } from "../LoginLayout/styles";
import logo from "../../assets/images/Safetechlogo.png";
import styles from "../DashboardLayout/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    margin: "2rem 1rem",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    marginBottom: "3rem",
  },
  subtitle: {
    fontWeight: "bold",
    margin: "1rem",
    fontSize: "1rem !important",
  },
  paragraph: {
    color: "#333333",
    lineHeight: "1.8",
    textAlign: "justify",
    margin: "1rem",
    fontSize: "1rem !important",
  },
  link: {
    color: "#3982cd",
  },
}));
const TermsService: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.image}>
        <Logo src={logo} alt="logo" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.title}>
          Terms and conditions
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            1. <strong>Program description:</strong> When opted-in, you will
            receive text messages (SMS) to your mobile number. These kinds of
            messages may include campus emergency alerts and, or staff emergency
            alert notifications.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            2. <strong>Program frequency:</strong> Message frequency will vary
            depending on the campus emergency drill schedule, unscheduled campus
            emergencies, and or staff emergency alert notification usage.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            3. <strong>Opt out:</strong> You can opt-out of this service at any
            time by contacting your campus Safety Commander. You agree to notify
            your Safety Commander if your phone number changes in order to
            update your SAFEtech profile.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            4. <strong>Help:</strong> If you are experiencing any issues, you
            can contact your campus Safety Commander or SAFEtech
            (safetech.company) for technical assistance.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            5. <strong>Interruption:</strong> SAFEtech is not liable for delayed
            or undelivered messages.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            6. <strong>Costs:</strong> SAFEtech will not charge for text
            messages you receive, however depending on your phone plan, message
            and data rates may apply for messages sent to you from us. If you
            have any questions about your text plan or data plan, please contact
            your wireless provider.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            7. <strong>Privacy:</strong> If you have any questions regarding
            privacy, please read our privacy policy section below.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          className={classes.title}
          sx={{ paddingTop: "40px" }}
        >
          Privacy Policy
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Data</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            SAFEtech collects personal information that you provide to us, such
            as your name, address, phone number and email address for user
            profile purposes. Your personal information will not be shared with
            any outside vendors.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Location</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            We do not collect or share anonymous location data.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Access</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            You can request access to see your stored information such as your
            name, address, email or phone through your campus Safety Commander.
            Your campus Safety Commander can deactivate and modify your account
            information upon request. Or, you can contact
            jmartin@safetech.company with any questions.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Deletion</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            SAFEtech may keep data indefinitely.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Sharing</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            SAFEtech will not share your personal information with any third
            party without your consent.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Ad Tracking</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            SAFEtech does not share your information with any ad companies.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Contact</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            If you have any questions or concerns about our privacy policies,
            please contact us: jmartin@safetech.company
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Vendors</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            We will not share your information with any outside vendors.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Special</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            Special situations may require disclosure of your data. To operate
            the service, we also may make identifiable and anonymous information
            available to third parties in these limited circumstances: (1) with
            your express consent, (2) when we have a good faith belief it is
            required by law, (3) when we have a good faith belief it is
            necessary to protect our rights or property, or (4) to any successor
            or purchaser in a merger, acquisition, liquidation, dissolution or
            sale of assets. Your consent will not be required for disclosure in
            these cases, but we will attempt to notify you, to the extent
            permitted by law to do so.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>More</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.paragraph}>
            SAFEtech will notify you of any privacy policy changes.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...styles.text, textAlign: "end" }}>
            Copyright {new Date().getFullYear()} SAFEtech. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsService;
