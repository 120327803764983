import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import moment from "moment";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Routes from "./pages/routes/_router";
import reduxStore from "./redux/store";
import { theme } from "./styles/theme";
import "./styles/globals.scss";
import Dialog from "./components/Dialog";
import { SocketProvider } from "./hooks/useSocket";
import { ProviderLoading } from "./hooks/useLoading";
import Loading from "./components/Loading/Loading";

moment.locale("es");

const App = () => {

  

  return (
    <>
      <ToastContainer />
      <Routes />
      <Dialog />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={reduxStore}>
          <SocketProvider>
            <ProviderLoading>
              <>
                <App />
                <Loading />
              </>
            </ProviderLoading>
          </SocketProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
