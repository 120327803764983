import {
  Button,
  Grid,
  Link,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { useState, KeyboardEvent, createRef } from "react";
import { RouteChildrenProps } from "react-router";

import { resetPasswordRequest } from "../../services/resetPassword";
import Form, { IFields, IFieldSchema } from "../Form";

const classes = {
  titleForward: {
    fontWeight: 600,
    opacity: 1,
    textAlign: "center",
    color: "#5D5D5D",
    fontSize: "18px",
    marginTop: "40px !important",
  } as const,
  generalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
  } as const,
  textGrid: {
    marginBottom: "1rem",
  } as const,
  containerOr: {
    flex: "1",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  } as const,
  textOr: {
    paddingLeft: "15px",
    paddingRight: "15px",
    color: "#5D5D5D",
    fontSize: "12px",
    opacity: "1",
  } as const,
  description: {
    fontWeight: "bold",
    textAlign: "center",
    overflow: "hidden",
    color: "#5D5D5D",
    fontSize: "12px",
    marginBottom: "24px",
    marginTop: "11px",
  } as const,
  detail: {
    width: "100%",
    whiteSpace: "initial",
    fontWeight: "bold",
    fontSize: "12px",
  } as const,
  sendMailButton: {
    width: "100%",
    height: "2.3rem",
    textTransform: "none",
    marginTop: "42px",
  } as const,
  mailInput: {
    width: "100%",
    padding: "0px",
  } as const,
  returnToLoginOptions: {
    fontSize: "0.8rem",
    textDecoration: "none",
    fontWeight: "bold",
  } as const,
  blueReturnToLoginButton: {
    fontWeight: "bold",
    cursor: "pointer",
    color: "#0052CC",
  } as const,
  grayReturnToLoginButton: {
    cursor: "pointer",
    color: "#5D5D5D",
  } as const,
  divider: {
    height: "1px",
    flex: "1",
    width: "100%",
    color: "#000000",
  } as const,
  containerInstructionSent: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    marginTop: "32px",
    alignItems: "center",
  } as const,
  titleSent: {
    fontWeight: "600",
    opacity: "1",
    textAlign: "center",
    color: "#5D5D5D",
    fontSize: "18px",
    marginBottom: "50px",
  } as const,
  descriptionSent: {
    fontWeight: "bold",
    textAlign: "center",
    overflow: "hidden",
    color: "#5D5D5D",
    fontSize: "12px",
    paddingLeft: "14px",
    marginBottom: "24px",
  } as const,
};

const resetPasswordRequestSchema: IFieldSchema[] = [
  {
    name: "email",
    validations: {
      maxLength: {
        max: 64,
        errorMessage: "The email can not contain more than 64 characters",
      },
      emailFormat: {
        errorMessage: "Invalid email",
      },
      required: {
        errorMessage: "Enter your email",
      },
    },
    value: "",
  },
];

interface IMessage {
  message: string;
  severity: string;
  email: string;
}

interface IResetPassForm extends RouteChildrenProps {
  returnToLoginClick?: CallableFunction;
}

const ResetPasswordRequestForm: React.FC<IResetPassForm> = (props) => {
  const { history } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>({
    message: "",
    severity: "",
    email: "",
  });
  const [fields, setFields] = useState<IFields>({});

  const handleRegister = async (fields: IFields): Promise<void> => {
    try {
      setLoading(true);
      const response = await resetPasswordRequest(
        fields.email as unknown as string,
      );

      if (response.data.success) {
        setMessage({
          message:
            "Your request to change your password has been sent successfully.",
          severity: "success",
          email: String(fields.email),
        });
      } else {
        setMessage({
          message: "There was an error on your request",
          severity: "error",
          email: String(fields.email),
        });
      }
      setFields(fields);
    } catch (error) {
      const err = error as AxiosError;
      setMessage({
        message: err.response?.data.message,
        severity: "error",
        email: String(fields.email),
      });
    } finally {
      setLoading(false);
    }
  };

  const sendButtonRef = createRef<HTMLButtonElement>();

  return (
    <Grid sx={classes.generalContainer}>
      {message.severity === "success" ? (
        <ContentSucess message={message} />
      ) : (
        /* Mostrar reset password si no hay mensaje de éxito  */
        <>
          <Grid container sx={classes.generalContainer}>
            <Typography variant="subtitle1" sx={classes.titleForward}>
              Forgot your password?
            </Typography>
            <Typography sx={classes.description}>
              Enter your email and we’ll send a recovery email with the
              instructions.
            </Typography>
          </Grid>
          <Form onSubmit={handleRegister} fields={resetPasswordRequestSchema}>
            {({ handleSubmit, handleChange, handleBlur, valid, fields }) => {
              const handleKeyPress = (event: KeyboardEvent) => {
                if (event.key === "Enter" && valid) {
                  handleSubmit();
                }
              };
              return (
                <Grid container direction="column" justifyContent="center">
                  <Grid item sx={{ maxHeight: "56px" }}>
                    <TextField
                      sx={classes.mailInput}
                      disabled={loading}
                      label="Email"
                      value={fields.email.value}
                      onChange={handleChange("email")}
                      variant="outlined"
                      error={
                        !!fields.email.errors.length ||
                        message.severity === "error"
                      }
                      helperText={
                        message.message !== ""
                          ? message.message
                          : fields.email.errors.join(". ")
                      }
                      fullWidth
                      onKeyPress={handleKeyPress}
                    />
                  </Grid>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    sx={classes.sendMailButton}
                    ref={sendButtonRef}
                  >
                    Send recovery email
                  </Button>
                </Grid>
              );
            }}
          </Form>
          <Grid
            sx={{ margin: "26px 0 10px 0", width: "100%" }}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid sx={classes.containerOr}>
              <Divider sx={classes.divider} />
              <Typography sx={classes.textOr}> Or </Typography>
              <Divider sx={classes.divider} />
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        sx={{ margin: "10px 0" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Link
          onClick={() => history.push("login")}
          underline="none"
          sx={{
            ...classes.returnToLoginOptions,
            cursor: "pointer",
            color: message.severity === "success" ? "#5d5d5d" : "#0052CC",
          }}
        >
          <Typography
            sx={{
              paddingTop: "0.6rem",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Return to Log in
          </Typography>
        </Link>
        {message.severity === "success" && (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              color="primary"
              underline="none"
              sx={{
                ...classes.returnToLoginOptions,
                cursor: "default",
                marginTop: "0.5rem",
              }}
            >
              <Typography sx={{ color: "#0052CC", fontWeight: "bold" }}>
                {" "}
                &nbsp;|&nbsp;{" "}
              </Typography>
            </Link>
            <Link
              onClick={() => handleRegister(fields)}
              underline="none"
              sx={{
                ...classes.returnToLoginOptions,
                cursor: "pointer",
                color: "#0052CC",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "0.6rem",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#0052CC",
                }}
              >
                Resend recovery email
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const ContentSucess: React.FC<any> = ({ message }) => {
  return (
    <Grid sx={classes.containerInstructionSent}>
      <Typography sx={classes.titleSent}>Instructions sent!</Typography>
      <Typography sx={classes.descriptionSent}>
        We sent an email with the instructions to change your password to &quot;
        {message.email}&quot;.
      </Typography>
      <Typography sx={classes.descriptionSent} style={{ marginTop: 0 }}>
        If you don’t received within 5 minutes please review your spam inbox.
      </Typography>
      <Divider sx={{ ...classes.divider, margin: "10px 0px" }} />
    </Grid>
  );
};

export default ResetPasswordRequestForm;
